






















import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class AiGenerateAgreementDialog extends Vue {
  /** Prop */

  /** ダイアログ開閉 */
  @Prop({ default: () => false }) value!: boolean;

  /** getter */
  private get Value(): boolean {
    return this.value;
  }

  /** setter */
  private set Value(newValue: boolean) {
    this.input(newValue);
  }

  /** Emit */

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }
}
