




















































































































































































































































































import {
  Component,
  Mixins,
  Prop,
  Emit,
  Ref,
  Watch
} from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import StateButton from "@/components/common_ibow/StateButton.vue";
import PatientInfoDialog from "@/components/patient/PatientInfoDialog.vue";
import PatientMemoDialog from "@/components/patient/PatientMemoDialog.vue";
import { PatientDispInfo } from "@/components/patient/types";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import { COLLECTION_SEARCH_COND_PATIENT_LIST } from "@/const/envFireStore";
import { SearchCondition } from "@/components/patient/list/type";
import { namespace } from "vuex-class";
import * as patient from "@/store/modules/patient/types";
import PatientMixin from "@/mixins/patientMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import * as appDate from "#/utility/appDate";
import { Link } from "#/types";

const Patient = namespace(patient.name);
const CurrentDate = new Date();

@Component({
  components: {
    StateButton,
    PatientInfoDialog,
    PatientMemoDialog
  }
})
export default class PatientHeader extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  PatientMixin,
  FocusingMixin
) {
  @Prop({ default: () => [] }) links!: Link[]; // ページ内リンク設定

  @Prop() title!: string; // 画面タイトル

  @Prop({ default: "" }) subTitle!: string; // サブタイトル

  @Emit("update:links")
  updateLinks(newValue: Link[]) {
    return newValue;
  }

  @Ref("header") readonly header!: HTMLElement;

  @Ref("patientInfoDialog") readonly patientInfoDialog!: PatientInfoDialog;

  @Ref("patientMemoDialog") readonly patientMemoDialog!: PatientMemoDialog;

  @Patient.Mutation(patient.SET_PATIENT) setPatient!: Function;

  private patientId = "0";

  // 利用者一覧検索でヒットした利用者IDs
  private resultPatientIds: number[] = [];

  // 検索での該当利用者がいないか今表示している利用者だけなら左右ボタンを表示しない
  private get showLinkNextPatient() {
    if (this.resultPatientIds.length === 0) {
      return false;
    } else if (
      this.resultPatientIds.length === 1 &&
      this.resultPatientIds[0] === Number(this.patientId)
    ) {
      return false;
    }
    return true;
  }

  private get info(): PatientDispInfo {
    return this.patientInfo; // 利用者情報
  }

  // 誕生日判定
  private get bDayIcon(): string {
    const b = appDate.strToDate(this.info.bday);
    if (CurrentDate.getMonth() != b.getMonth()) {
      return "";
    }
    const diffDate = b.getDate() - CurrentDate.getDate();
    if (diffDate < 0 || diffDate > 3) {
      return "";
    }
    switch (diffDate) {
      case 3:
        return "/images/birthday3.png";
      case 2:
        return "/images/birthday2.png";
      case 1:
        return "/images/birthday1.png";
      case 0:
        return "/images/birthday0.png";
      default:
        return "";
    }
  }

  // パネル展開状態
  private openedPanels: number[] = [];

  @Watch("$route") watchRoute() {
    if (this.patientId != this.$route.params.id) {
      this.patientId = this.$route.params.id;
      this.addReadHistory();
    }
    this.updateHeader();
  }

  public created() {
    this.patientId = this.$route.params.id;
    this.addReadHistory();
    this.updateHeader();
  }

  /** ヘッダー情報更新 */
  private updateHeader() {
    this.postJsonBackground(
      window.base_url + "/api/patient/header/get",
      { patient_id: Number(this.patientId) },
      res => {
        if (!res.data) return;
        this.setPatient(res.data);
        const breadItems = this.BreadItems;
        if (breadItems?.[1]) {
          breadItems[1].text = this.patientInfo.name;
          this.setBreadItems(breadItems);
        }
      }
    );
  }

  // 利用者参照履歴追加(最大10件保持)
  private async addReadHistory() {
    // fire store から参照履歴取得
    this.collection = COLLECTION_SEARCH_COND_PATIENT_LIST;
    this.documentId = String(this.loginUser.id);
    const cond = await this.documentGet();
    if (cond === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    const ids = (cond as SearchCondition).read_history_ids;

    // 検索結果にヒットした利用者がいれば、そのリストを保持
    const resultIds = (cond as SearchCondition).result_patient_ids;
    if (resultIds && resultIds.length > 0) {
      this.resultPatientIds = resultIds;
    }

    const index = ids.indexOf(Number(this.patientId));
    if (index != -1) {
      // 参照履歴にある利用者の場合、配列の末尾に追加するため一度削除する
      ids.splice(index, 1);
    }
    // 参照履歴に利用者追加
    ids.push(Number(this.patientId));
    // 10件以上になった場合は古い履歴（配列の先頭）を削除する
    if (ids.length > 10) {
      (cond as SearchCondition).read_history_ids = ids.slice(1, 11);
    }
    // fire storeに保存
    if (cond) {
      await this.documentSave(cond as SearchCondition);
    }
  }

  // 生年月日
  private get birthDay(): string {
    return `(${this.info.bday_wareki}) ${this.info.age}`;
  }

  // 利用者切り替え
  private get showPatientChangeButton(): boolean {
    const path = this.$route.path;
    const parse = path.split("/");
    if (parse[1] == "visit") {
      // 訪問へ行くの場合切り替えボタンを表示しない
      return false;
    }
    return true;
  }

  private async clickLeft() {
    this.changePatientId(false);
  }

  private async clickRight() {
    this.changePatientId(true);
  }

  private async changePatientId(isNext: boolean) {
    const id = Number(this.$route.params.id);
    const cond = await this.documentGet();
    const ids = (cond as SearchCondition).result_patient_ids;
    if (!ids || ids.length === 0) {
      await this.$openAlert(
        "表示できる利用者がいません。利用者検索画面にて一人以上が当てはまる条件で検索してから、再度お試しください。"
      );
      return;
    }
    this.resultPatientIds = ids;
    const nextId = this.getChangePatientId(isNext, id, ids);
    const parse = this.$route.path.split("/");
    let movePath = `/patient/${nextId}/${parse[3]}`;
    if (parse[3] == "report-group") {
      movePath += `/${parse[4]}`;
    }
    this.$router.push({
      path: movePath,
      query: this.$route.query
    });
  }
}
