











import { Component, Prop, Vue } from "vue-property-decorator";
import AiGenerateAgreementDialog from "@/components/patient/common/aireport/AiGenerateAgreementDialog.vue";

@Component({
  components: {
    AiGenerateAgreementDialog
  }
})
export default class AiGenerateButtonNotAgreement extends Vue {
  /** Prop */

  /** ボタン高さ */
  @Prop({ default: "36px" }) height!: string;

  /** 変数 */

  /** ダイアログ表示制御 */
  private dialog = false;
}
