interface Choice {
  text: string;
  value: number;
}

// 繰り返し区分
export const RepeatDiv = {
  None: 1, // 繰り返しなし
  EveryDay: 2, // 毎日
  EveryWeek: 3, // 毎週
  EveryMonthSpecifiedDate: 4, // 毎月（日付指定）
  EveryMonthSpecifiedWeek: 5, // 毎月（週指定）
  EveryMonthSpecifiedWeekDay: 6 // 毎月（曜日指定）
};
export type RepeatDiv = typeof RepeatDiv[keyof typeof RepeatDiv];

// 繰り返し期限区分
export const RepeatPeriodDiv = {
  Date: 0, // 日付
  Count: 1 // 回数
};
export type RepeatPeriodDiv = typeof RepeatPeriodDiv[keyof typeof RepeatPeriodDiv];

export const RepeatItemsNotNoRepeat = [
  {
    text: "毎日",
    value: RepeatDiv.EveryDay
  },
  {
    text: "毎週",
    value: RepeatDiv.EveryWeek
  },
  {
    text: "毎月（日付指定）",
    value: RepeatDiv.EveryMonthSpecifiedDate
  },
  {
    text: "毎月（週指定）",
    value: RepeatDiv.EveryMonthSpecifiedWeek
  },
  {
    text: "毎月（曜日指定）",
    value: RepeatDiv.EveryMonthSpecifiedWeekDay
  }
] as Choice[];

export const RepeatItems = [
  {
    text: "繰り返さない",
    value: RepeatDiv.None
  },
  ...RepeatItemsNotNoRepeat
] as Choice[];

// 曜日選択肢
export const WeekDays = ["日", "月", "火", "水", "木", "金", "土"];

// 曜日週選択肢
export const WeekDayTimes = ["１回目", "２回目", "３回目", "４回目", "５回目"];

// 週選択肢
export const Weeks = ["１週", "２週", "３週", "４週", "５週", "６週"];

// 週間隔選択肢
export const WeekIntervals = [
  { text: "毎週", value: 0 },
  { text: "２週間毎", value: 1 },
  { text: "３週間毎", value: 2 }
] as Choice[];

// 月間隔選択肢
export const MonthIntervals = [
  { text: "毎月", value: 0 },
  { text: "２ヶ月ごと", value: 1 },
  { text: "３ヶ月ごと", value: 2 }
] as Choice[];

// イベントステータス
export const EventStatuses = [
  { text: "有効", value: 1 },
  { text: "キャンセル", value: 0 }
] as Choice[];

// 編集範囲区分
export const EditRangeDiv = {
  New: 0, // 新規 | 単独
  This: 1, // この予定
  After: 2, // これ以降の予定
  All: 3 // 一連の予定
};
export type EditRangeDiv = typeof EditRangeDiv[keyof typeof EditRangeDiv];
// 編集範囲
export const EditRange = [
  { text: "この予定", value: EditRangeDiv.This },
  { text: "これ以降の予定", value: EditRangeDiv.After },
  { text: "一連の予定", value: EditRangeDiv.All }
] as Choice[];

// カレンダーインターフェース
export interface Calendar {
  $el: HTMLElement;
  title: string;
  times: {
    now: {
      date: string;
      hour: number;
    };
  };
  lastStart: {
    date: string;
  };
  lastEnd: {
    date: string;
  };
  timeToY(arg0: unknown): number;
  checkChange(): void;
  prev(): void;
  next(): void;
  scrollToTime(time: string): void;
}

/** カレンダーイベント */
export interface CalendarDispEvent {
  /** ID */ id: string;
  /** レイヤーID */ layer_id: number;
  /** イベントID */ event_id: number;
  /** イベント種類 1:職員予定 2:訪問予定(職員あり) 3:シフト 4:勤怠シフト 5:休日 6:アカウント予定 7:訪問予定予定(職員なし) 8:利用者個別予定 */ type: number;
  /** 状態 0:有効 1:キャンセル 2:未回答 3:参加する 4:参加しない */ status: number;
  /** カラー */ color: string;
  /** クラス */ class: string;
  /** 表示名 */ name: string;
  /** ツールチップ用の表示名 */ name_toolchip: string;
  /** 職員名 */ visitor_name: string;
  /** 職員名+(職種) */ visitor_name_and_job: string;
  /** 開始日時 */ start: string;
  /** 終了日時 */ end: string;
  /** 時間予定フラグ false:終日 true:時間予定 */ timed: boolean;
  /** 繰り返し予定フラグ false:単発の予定 true:繰り返し予定 */ is_repeat: boolean;
  /** カテゴリ */ category: string;
  /** 職員ID */ staff_id: number;
  /** ボタン種類 0:ボタンなし 1:編集ボタン 2:参加ボタン 3:削除ボタン */ button_type: number;
  /** スタイル */ style: string;
  /** 参加状況 */ participants: Participant[];
  /** 訪問予定情報 */ visit_plan: VisitPlan;
  /** 職員予定情報 */ staff_schedule: StaffSchedule;
  /** 印刷用情報 */ print_value: PrintValue;
}

/** カレンダーイベント(移動あり) */
export interface CalendarDispEventMoving extends CalendarDispEvent {
  tmp_timed: boolean; //終日の場合 false
  is_not_moving: boolean; //移動不可の場合 true
}

/** レイヤー */
export interface Layer {
  /** ID */ id: number;
  /** 職員ID */ staff_id: number;
  /** ewellユーザーID */ ewell_user_id: number;
  /** レイヤー名 */ name: string;
  /** 色 */ color: string;
  /** ラベル */ label: string;
  /** クラス */ class: string;
  /** 権限名 */ auth_name: string;
  /** 事業所名 */ office_name: string;
}

/** 参加状況 */
export interface Participant {
  /** 職員ID */ staff_id: number;
  /** 職員名 */ name: string;
  /** 色 */ color: string;
  /** アイコン */ icon: string;
}

/** 訪問予定情報 */
export interface VisitPlan {
  /** 繰り返しID */ rule_id: number;
  /** 利用者ID */ patient_id: number;
  /** 利用者名 */ patient_name: string;
  /** 利用者個別予定のイベント名 */ event_name: string;
  /** メモ */ memo: string;
  /** 開始日時と終了日時 */ start_and_end: string;
  /** 開始時分 */ start_hm: string;
  /** 訪問職員名 */ visitor_names: string;
  /** 利用者個別予定メモ */ content: string;
}

/** 職員予定情報 */
export interface StaffSchedule {
  /** 場所 */ locate: string;
  /** 開始日時と終了日時 */ start_and_end: string;
  /** 自分の参加状況 */ own_participant: {
    /** 自分の参加状況 */ status: number;
  };
  /** 内容 */ content: string;
  /** タイトル */ title: string;
}

/** 印刷用情報 */
export interface PrintValue {
  /** PDF出力用の値 */ pdf_value: string;
  /** PDF出力用の値表示切り替え用 */ pdf_value_plus: string;
}

/** カレンダーイベントデフォルト */
export const DefaultEvent = (): CalendarDispEvent => ({
  id: "",
  layer_id: 0,
  event_id: 0,
  type: 0,
  status: 0,
  color: "",
  class: "",
  name: "",
  name_toolchip: "",
  visitor_name: "",
  visitor_name_and_job: "",
  start: "",
  end: "",
  timed: false,
  is_repeat: false,
  category: "",
  staff_id: 0,
  button_type: 0,
  style: "",
  participants: [],
  visit_plan: {
    rule_id: 0,
    patient_id: 0,
    patient_name: "",
    event_name: "",
    memo: "",
    start_and_end: "",
    start_hm: "",
    visitor_names: "",
    content: ""
  },
  staff_schedule: {
    locate: "",
    start_and_end: "",
    own_participant: {
      status: 0
    },
    content: "",
    title: ""
  },
  print_value: {
    pdf_value: "",
    pdf_value_plus: ""
  }
});

/** レイヤーイベントデフォルト */
export const DefaultLayer = (color: string): Layer => ({
  id: 0,
  staff_id: 0,
  ewell_user_id: 0,
  name: "",
  color: color,
  label: "",
  class: "",
  auth_name: "",
  office_name: ""
});

// 曜日初期化
export const getEventWeekDays = (value: string, targetdate: Date): string => {
  if (value == "0000000") {
    const weekdays = value.split("");
    weekdays[targetdate.getDay()] = "1";
    return weekdays.join("");
  }
  return value;
};

// 週（X週目）初期化
export const getEventWeeks = (value: string, targetdate: Date): string => {
  if (value == "000000") {
    const weeks = value.split("");
    const date = new Date(targetdate);
    date.setDate(1);
    const diffWeekday = 6 - date.getDay();
    date.setDate(date.getDate() + diffWeekday);
    for (let i = 0; i < weeks.length; i++) {
      if (date.getTime() >= targetdate.getTime()) {
        weeks[i] = "1";
        break;
      }
      date.setDate(date.getDate() + 7);
    }
    return weeks.join("");
  }
  return value;
};

// 週（X回目）初期化
export const getEventWeekTimes = (value: string, targetdate: Date): string => {
  if (value == "00000") {
    const weekTimes = value.split("");
    const date = new Date(targetdate);
    date.setDate(1);
    let dayCount = 0;
    while (date.getTime() < targetdate.getTime()) {
      if (date.getDay() == targetdate.getDay()) {
        dayCount++;
      }
      date.setDate(date.getDate() + 1);
    }
    weekTimes[dayCount] = "1";
    return weekTimes.join("");
  }
  return value;
};
