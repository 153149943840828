















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit, Watch } from "vue-property-decorator";
import AppTextField from "../AppTextField.vue";
import AppChatSelectedStaff from "./AppChatSelectedStaff.vue";
import AppChatStaffListItem from "./AppChatStaffListItem.vue";

interface Staff {
  id: number;
  ewellUserId: number;
  iconPath?: string;
  staffName: string;
  officeName: string;
}

@Component({
  components: { AppTextField, AppChatSelectedStaff, AppChatStaffListItem }
})
export default class AppChatStaffSelector extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  staffs!: Staff[];
  @Prop({
    default: () => {
      return [];
    }
  })
  staffIds!: number[];
  @Prop() selfEwellUserId!: number;
  @Prop({ default: true }) isFetching!: boolean;

  private filterCharacters = "";
  private selectedStaffs: Staff[] = [];

  private get UnselectedStaffs() {
    return this.staffs.filter(staff => {
      // チャットのやり取りは iBow アカウントベースで行う
      const isSelected = this.selectedStaffs.some(selected => {
        return selected.ewellUserId === staff.ewellUserId;
      });
      // 自分は選択肢として表示しない
      const isSelf = staff.ewellUserId === this.selfEwellUserId;

      // 職員名と事業所名で絞り込みが可能
      if (this.filterCharacters) {
        return (
          !isSelected &&
          !isSelf &&
          staff.staffName.includes(this.filterCharacters)
        );
      }

      return !isSelected && !isSelf;
    });
  }

  @Watch("staffs")
  private staffsDidChange() {
    const mapped = this.staffIds.map(id => {
      return this.staffs.find(staff => {
        // 自分は選択されていても表示しない
        return staff.id === id && staff.ewellUserId !== this.selfEwellUserId;
      });
    });

    this.selectedStaffs = mapped.filter((data): data is Staff => {
      return data !== undefined;
    });
  }

  @Emit()
  private addStaff(staff: Staff) {
    this.selectedStaffs.push(staff);
    return {
      id: staff.id,
      name: staff.staffName,
      ewellUserId: staff.ewellUserId,
    };
  }

  @Emit()
  private removeStaff(staff: Staff) {
    const index = this.selectedStaffs.findIndex(selected => {
      return selected.ewellUserId === staff.ewellUserId;
    });

    if (index > -1) {
      this.selectedStaffs.splice(index, 1);
    }

    return {
      id: staff.id,
      name: staff.staffName,
      ewellUserId: staff.ewellUserId,
    };
  }
}
