import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/views/Home.vue";
import LinkAccount from "@/views/login/SelectLinkAccount.vue";
import Calendar from "@/views/calendar/Calendar.vue";
import ScheduleManage from "@/views/schedule/ScheduleManage.vue";
import PatientList from "@/views/patient/PatientList.vue";
import PatientAccept from "@/views/patient/accept/PatientAccept.vue";
import VisitPatientList from "@/views/visit/VisitPatientList.vue";
import VisitBase from "@/components/visit/VisitBase.vue";
import VisitMove from "@/views/visit/VisitMove.vue";
import VisitStay from "@/views/visit/VisitStay.vue";
import VisitMedicinetaking from "@/views/patient/medicine/MedicineTaking.vue";
import VisitMedicinetakingEdit from "@/views/patient/medicine/MedicineTakingEdit.vue";
import VisitProgress from "@/views/patient/progress/PatientProgress.vue";
import VisitHour24Regist from "@/views/patient/hour24/Hour24Regist.vue";
import VisitShareFiles from "@/views/sharefile/ShareFiles.vue";
import VisitPatientFiles from "@/views/patient/file/PatientFile.vue";
import SubmitList from "@/views/submit/SubmitList.vue";
import PatientBase from "@/components/patient/PatientBase.vue";
import PatientInfo from "@/views/patient/info/PatientInfo.vue";
import PatientHistoryList from "@/views/patient/info/PatientHistoryList.vue";
import PatientHistoryEdit from "@/views/patient/info/PatientHistoryEdit.vue";
import PatientInsuranceInfo from "@/views/patient/insuranceinfo/InsuranceInfo.vue";
import InsuranceInfoMed from "@/views/patient/insuranceinfo/InsuranceInfoMed.vue";
import InsuranceInfoEldCert from "@/views/patient/insuranceinfo/InsuranceInfoEldCert.vue";
import InsuranceInfoHighCost from "@/views/patient/insuranceinfo/InsuranceInfoHighCost.vue";
import InsuranceInfoLimitCert from "@/views/patient/insuranceinfo/InsuranceInfoLimitCert.vue";
import InsuranceInfoCare from "@/views/patient/insuranceinfo/InsuranceInfoCare.vue";
import InsuranceInfoCareRateCert from "@/views/patient/insuranceinfo/InsuranceInfoCareRateCert.vue";
import InsuranceInfoPublic from "@/views/patient/insuranceinfo/InsuranceInfoPublic.vue";
import PatientIndicate from "@/views/patient/indicate/PatientIndicate.vue";
import PatientIndicateEdit from "@/views/patient/indicate/PatientIndicateEdit.vue";
import PatientSpecialIndicateEdit from "@/views/patient/indicate/PatientSpecialIndicateEdit.vue";
import PatientCalendar from "@/views/patient/calendar/PatientCalendar.vue";
import PatientRecord2List from "@/views/patient/record2/Record2List.vue";
import PatientRecord2Edit from "@/views/patient/record2/Record2.vue";
import PatientProgress from "@/views/patient/progress/PatientProgress.vue";
import PrintProgress from "@/components/patient/progress/PrintProgress.vue";
import PatientHour24List from "@/views/patient/hour24/Hour24List.vue";
import PatientHour24Regist from "@/views/patient/hour24/Hour24Regist.vue";
import PatientSelfburden from "@/views/patient/selfburden/PatientSelfburden.vue";
import PatientSelfburdenEdit from "@/views/patient/selfburden/PatientSelfburdenEdit.vue";
import PatientSelfburdenTemplateEdit from "@/views/patient/selfburden/PatientSelfburdenTemplateEdit.vue";
import PatientAdlList from "@/views/patient/adl/AdlList.vue";
import PatientAdlEdit from "@/views/patient/adl/AdlCreate.vue";
import PatientAdlConfirmation from "@/views/patient/adl/AdlConfirmation.vue";
import PatientGroupSetting from "@/views/patient/group/GroupSettings.vue";
import PatientFiles from "@/views/patient/file/PatientFile.vue";
import PatientVnrecordEdit from "@/views/patient/vnrecord/PatientVnrecord.vue";
import PatientReportEdit from "@/views/patient/report/Report.vue";
import ProvisionEdit from "@/views/patient/provision/ProvisionCreate.vue";
import PatientPlanEdit from "@/views/patient/plan/PatientPlan.vue";
import PatientMedicineTaking from "@/views/patient/medicine/MedicineTaking.vue";
import PatientMedicineTakingEdit from "@/views/patient/medicine/MedicineTakingEdit.vue";
import PatientServiceEdit from "@/views/patient/service/ServiceEdit.vue";
import PatientBedsoreEdit from "@/views/patient/bedsore/PatientBedsore.vue";
import PatientCareSummaryEdit from "@/views/patient/caresummary/CareSummary.vue";
import PatientOccurEdit from "@/views/patient/occur/OccurCreate.vue";
import PatientConferenceEdit from "@/views/patient/conference/ConferenceCreate.vue";
import PatientDocumentList from "@/views/patient/document/DocumentList.vue";
import PatientCareplan from "@/views/patient/careplan/PatientCareplan.vue";
import OnlineCapabilities from "@/views/patient/onlinecap/OnlineCapabilities.vue";
import ReportGroup from "@/components/patient/ReportGroup.vue";
import PatientVnrecordList from "@/views/patient/vnrecord/PatientVnrecordList.vue";
import PatientReportList from "@/views/patient/report/ReportList.vue";
import PatientPlanList from "@/views/patient/plan/PatientPlanList.vue";
import PatientServiceList from "@/views/patient/service/ServiceList.vue";
import PatientBedsoreList from "@/views/patient/bedsore/PatientBedsoreList.vue";
import PatientCareSummaryList from "@/views/patient/caresummary/CareSummaryList.vue";
import PatientOccurList from "@/views/patient/occur/Occur.vue";
import PatientConferenceList from "@/views/patient/conference/ConferenceList.vue";
import PatientProvisionList from "@/views/patient/provision/Provision.vue";
import PatientPdfLinkMailList from "@/views/patient/pdflink/PdfLinkMailList.vue";
import PatientPdfLinkMailEdit from "@/views/patient/pdflink/PdfLinkMailEdit.vue";
import PatientPdfLinkMailDetail from "@/views/patient/pdflink/PdfLinkMailDetail.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import DashboardStatistics from "@/views/dashboard/DashboardStatistics.vue";
import DashboardReport from "@/views/dashboard/DashboardReport.vue";
import DashboardTables from "@/views/dashboard/DashboardTables.vue";
import Careplan from "@/views/careplan/Careplan.vue";
import UnsubmitList from "@/views/unsubmit/UnsubmitList.vue";
import Hour24List from "@/views/hour24/Hour24List.vue";
import Hour24Board from "@/views/hour24/Hour24Board.vue";
import Bbs from "@/views/bbs/Bbs.vue";
import BbsNotice from "@/views/bbs/BbsNotice.vue";
import BbsDetail from "@/views/bbs/BbsDetail.vue";
import StaffShift from "@/views/shift/StaffShift.vue";
import Reciept from "@/views/reciept/Reciept.vue";
import PatientRecieptDetail from "@/views/patient/receipt/RecieptDetail.vue";
import BiHome from "@/views/officeaggregate/BiHome.vue";
import OfficeInvoice from "@/views/officeaggregate/officeinvoice/OfficeInvoice.vue";
import OfficeInvoiceDetail from "@/views/officeaggregate/officeinvoice/OfficeInvoiceDetail.vue";
import OfficeInvoiceStaff from "@/views/officeaggregate/officeinvoice/OfficeInvoiceStaff.vue";
import OldOfficeInvoice from "@/views/officeinvoice/OfficeInvoice.vue";
import OldOfficeInvoiceDetail from "@/views/officeinvoice/OfficeInvoiceDetail.vue";
import OldOfficeInvoiceStaff from "@/views/officeinvoice/OfficeInvoiceStaff.vue";
import ReceiptSales from "@/views/officeaggregate/receiptSales/ReceiptSales.vue";
import ReceiptSalesDetail from "@/views/officeaggregate/receiptSales/ReceiptSalesDetail.vue";
import ReceiptSalesPatient from "@/views/officeaggregate/receiptSales/ReceiptSalesPatient.vue";
import PatientNumber from "@/views/officeaggregate/patientNumber/PatientNumber.vue";
import PatientContinuityRate from "@/views/officeaggregate/patientContinuityRate/PatientContinuityRate.vue";
import IndicateNumber from "@/views/officeaggregate/indicateNumber/IndicateNumber.vue";
import UnitCost from "@/views/officeaggregate/unitCost/UnitCost.vue";
import UnitCostDetail from "@/views/officeaggregate/unitCost/UnitCostDetail.vue";
import ShareFile from "@/views/sharefile/ShareFile.vue";
import ShareFiles from "@/views/sharefile/ShareFiles.vue";
import ShareFilePatient from "@/views/sharefile/ShareFilePatient.vue";
import Mastermaintenance from "@/views/master/Master.vue";
import MasterBase from "@/components/master/MasterBase.vue";
import AgreeInq from "@/views/master/agreeinq/AgreeInq.vue";
import MastermaintenanceOffice from "@/views/master/office/OfficeList.vue";
import MastermaintenanceOfficeHistory from "@/views/master/office/OfficeHistory.vue";
import MastermaintenanceOfficeEdit from "@/views/master/office/OfficeEdit.vue";
import MastermaintenanceOfficeNew from "@/views/master/office/OfficeEdit.vue";
import MastermaintenanceOfficeHistoryEdit from "@/views/master/office/OfficeHistoryEdit.vue";
import NoticeSetting from "@/views/master/notice/NoticeSetting.vue";
import StaffList from "@/views/master/staff/StaffList.vue";
import MasterStaffShift from "@/views/shift/StaffShift.vue";
import StaffHistory from "@/views/master/staff/StaffHistory.vue";
import StaffEdit from "@/views/master/staff/StaffEdit.vue";
import CorpEntry from "@/views/master/corp/CorpEntry.vue";
import CorpNew from "@/views/master/corp/CorpNew.vue";
import CorpEdit from "@/views/master/corp/CorpEdit.vue";
import MedicalEntry from "@/views/master/medical/MedicalEntry.vue";
import MedicalNew from "@/views/master/medical/MedicalNew.vue";
import MedicalEdit from "@/views/master/medical/MedicalEdit.vue";
import FacilityList from "@/views/master/facility/FacilityList.vue";
import FacilityEdit from "@/views/master/facility/FacilityEdit.vue";
import CareStationEntry from "@/views/master/carestation/CareStationEntry.vue";
import CareStationNew from "@/views/master/carestation/CareStationNew.vue";
import CareStationEdit from "@/views/master/carestation/CareStationEdit.vue";
import InsurerEntry from "@/views/master/insurer/InsurerEntry.vue";
import InsurerNew from "@/views/master/insurer/InsurerNew.vue";
import InsurerEdit from "@/views/master/insurer/InsurerEdit.vue";
import MastermaintenanceNursingPlanList from "@/views/master/nursingplan/NursingPlanList.vue";
import NursingContent from "@/views/master/nursing_content/NursingContentList.vue";
import ObserveItemCategoryList from "@/views/master/observeitem/ObserveItemCategoryList.vue";
import AdlContentList from "@/views/master/adlcontent/AdlContentList.vue";
import AdlItemList from "@/views/master/adlitem/AdlItemList.vue";
import StdEvalItemList from "@/views/master/stdevalitem/StdEvalItemList.vue";
import MedicineTakingList from "@/views/master/medicinetaking/MedicineTakingList.vue";
import PublicMoneyKindEntry from "@/views/master/public_money_kind/PublicMoneyKindEntry.vue";
import RelationList from "@/views/master/relation/RelationList.vue";
import MastermaintenanceGroupList from "@/views/master/group/GroupList.vue";
import FileTagList from "@/views/master/filetag/FileTagList.vue";
import OwnExpenseService from "@/views/master/own_expense_service/OwnExpenseServiceList.vue";
import MastermaintenanceCsvNormalOutput from "@/views/master/normal_csv/NormalCsvOutput.vue";
import PatientInvoice from "@/views/master/patientinvoice/PatientInvoice.vue";
import Record2BulkOutput from "@/views/master/record2_bulk_output/Record2BulkOutput.vue";
import InfoCoord from "@/views/master/info_coord/InfoCoord.vue";
import InfoCoordIO from "@/views/master/info_coord/InfoCoordIO.vue";
import SukoyakasanLink from "@/views/master/sukoyakasan/SukoyakasanLink.vue";
import CsvInput from "@/views/master/choju/CsvInput.vue";
import MastermaintenanceCsvChoujuOutput from "@/views/master/chouju_csv/ChoujuCsvOutput.vue";
import WisemanImport from "@/views/master/wiseman/WisemanImport.vue";
import CsvOutputWiseman from "@/views/master/csvoutputwiseman/CsvOutputWiseman.vue";
import ExcelInput from "@/views/master/excel/ExcelInput.vue";
import ExcelOutput from "@/views/master/excel/ExcelOutput.vue";
import MedTransmissionExcelInput from "@/views/master/med_transmission_excel/MedTransmissionExcelInput.vue";
import MedTransmissionExcelOutput from "@/views/master/med_transmission_excel/MedTransmissionExcelOutput.vue";
import BatchStaffLink from "@/views/master/batch_staff_link/BatchStaffLink.vue";
import MastermaintenanceOperationLog from "@/views/master/operationlog/OperationLog.vue";
import RestoreMasterPatient from "@/views/master/restore/RestoreMasterPatient.vue";
import Contact from "@/views/contact/Contact.vue";
import ContactDetail from "@/views/contact/ContactDetail.vue";
import ContactHistory from "@/views/contact/ContactHistory.vue";
import ContactNew from "@/views/contact/ContactNew.vue";
import FAQ from "@/views/contact/FAQ.vue";
import FAQDetail from "@/views/contact/FAQShow.vue";
import IbowKintaiLink from "@/views/ibowkintai/IbowKintaiLink.vue";
import StaffCalendarPrint from "@/views/calendar/StaffCalendarPrint.vue";
import CalendarWebPrint from "@/components/schedule/CalendarWebPrint.vue";
import AiRecommendDisease from "@/components/common_ibow/AiRecommendDisease.vue";
import PreCheck from "@/views/reciept/precheck/PreCheck.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // ホーム
    path: "/",
    name: "Home",
    component: Home
  },
  {
    // 契約別ログイン
    path: "/login/:agreementId",
    name: "Login"
  },
  {
    // V1連携ログイン
    path: "/login-link/:agreementId",
    name: "LoginLink"
  },
  {
    // PDF表示画面
    path: "/pdf-open",
    name: "PdfOpen"
  },
  {
    // ファイル表示画面
    path: "/file-open",
    name: "FileOpen"
  },
  {
    // iBowアカウント連携選択
    path: "/link-account",
    name: "LinkAccount",
    component: LinkAccount
  },
  {
    // PDFリンク PDF表示画面
    path: "/pdflink/view/:linkKey",
    name: "PdfLinkShow"
  },
  {
    // カレンダー
    path: "/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    // カレンダーWebプリント
    path: "/calendar/:id",
    name: "StaffCalendarPrint",
    component: StaffCalendarPrint,
    meta: { isNoCommonArea: 1 }
  },
  {
    //訪問予定管理
    path: "/schedule",
    name: "ScheduleManage",
    component: ScheduleManage
  },
  {
    //利用者一覧
    path: "/patient",
    name: "PatientList",
    alias: "/patient/list",
    component: PatientList
  },
  {
    //利用者新規受付
    path: "/patient/accept",
    name: "PatientAccept",
    component: PatientAccept
  },
  {
    //オンライン資格確認取込
    path: "/onlinecap",
    name: "OnlineCapabilities",
    component: OnlineCapabilities
  },
  ////// 訪問 ///////
  {
    // 訪問へ行く：利用者検索
    path: "/visit/patients",
    name: "VisitPatientList",
    component: VisitPatientList
  },
  {
    // 訪問共通コンポーネント
    path: "/visit/:id",
    component: VisitBase,
    children: [
      {
        // 訪問へ行く：移動中
        path: "move",
        name: "VisitMove",
        component: VisitMove
      },
      {
        // 訪問へ行く：訪問中
        path: "stay",
        name: "VisitStay",
        component: VisitStay
      },
      {
        // 服薬管理表（訪問）
        path: "medicinetaking",
        name: "VisitMedicinetaking",
        component: VisitMedicinetaking,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 服薬管理表編集（訪問）
        path: "medicinetaking/edit/:medicine_id",
        name: "VisitMedicinetakingEdit",
        component: VisitMedicinetakingEdit,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 経過観察（訪問）
        path: "progress",
        name: "VisitProgress",
        component: VisitProgress,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 24時間体制登録（訪問）
        path: "hour24/regist/:fulltimeid",
        name: "VisitHour24Regist",
        component: VisitHour24Regist,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 共有ファイル
        path: "sharefile",
        name: "VisitShareFiles",
        component: VisitShareFiles,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 利用者ファイル
        path: "patientfile",
        name: "VisitPatientFiles",
        component: VisitPatientFiles,
        meta: { isNoCommonArea: 1 }
      },
      {
        // カレンダーweb印刷画面
        path: "calendarprint/:id",
        name: "CalendarWebPrint",
        component: CalendarWebPrint,
        meta: { isNoCommonArea: 1 }
      }
    ]
  },
  {
    // 記録を提出 利用者ごとの看護記録書Ⅱ一覧
    path: "/submit/list",
    name: "SubmitList",
    component: SubmitList
  },
  {
    // 利用者共通コンポーネント
    path: "/patient/:id",
    component: PatientBase,
    redirect: "/patient/:id/calendar",
    children: [
      ////// 利用者情報を確認 ///////
      {
        // 基礎情報
        path: "info",
        name: "PatientInfo",
        component: PatientInfo
      },
      {
        // 利用者履歴 一覧
        path: "info/history",
        name: "PatientHistoryList",
        component: PatientHistoryList
      },
      {
        // 利用者履歴 編集
        path: "info/history/edit/:historyId",
        name: "PatientHistoryEdit",
        component: PatientHistoryEdit
      },
      {
        // 保険情報
        path: "insurance",
        name: "PatientInsuranceInfo",
        component: PatientInsuranceInfo
      },
      {
        // 利用者 医療保険登録
        path: "insurance/med/:insmedid",
        name: "InsuranceInfoMed",
        component: InsuranceInfoMed
      },
      {
        // 利用者 高齢受給者証登録
        path: "insurance/eldcert/:inseldcertid",
        name: "InsuranceInfoEldCert",
        component: InsuranceInfoEldCert
      },
      {
        // 利用者 高額療養費登録
        path: "insurance/highcost/:inshighcostid",
        name: "InsuranceInfoHighCost",
        component: InsuranceInfoHighCost
      },
      {
        // 利用者 限度額適用認定証登録
        path: "insurance/limitcert/:inslimitcertid",
        name: "InsuranceInfoLimitCert",
        component: InsuranceInfoLimitCert
      },
      {
        // 利用者 介護保険登録
        path: "insurance/care/:inscareid",
        name: "InsuranceInfoCare",
        component: InsuranceInfoCare
      },
      {
        // 利用者 介護保険負担割合証登録
        path: "insurance/care/ratecert/:inscareratecertid",
        name: "InsuranceInfoCareRateCert",
        component: InsuranceInfoCareRateCert
      },
      {
        // 利用者 公費登録
        path: "insurance/public/:inspublicid",
        name: "InsuranceInfoPublic",
        component: InsuranceInfoPublic
      },
      {
        // 利用者 指示書
        path: "indicate",
        name: "PatientIndicate",
        component: PatientIndicate
      },
      {
        // 利用者 指示書編集
        path: "indicate/edit/:indicateid",
        name: "PatientIndicateEdit",
        component: PatientIndicateEdit
      },
      {
        // 利用者 特別指示書編集
        path: "indicate/special/edit/:specialindicateid",
        name: "PatientSpecialIndicateEdit",
        component: PatientSpecialIndicateEdit
      },
      {
        // 利用者 カレンダー
        path: "calendar",
        name: "PatientCalendar",
        component: PatientCalendar
      },
      ////// 利用者情報を入力 ///////
      {
        // 利用者 記録を提出 一覧
        path: "record2",
        name: "PatientRecord2List",
        component: PatientRecord2List
      },
      {
        // 利用者 記録を提出 編集
        path: "record2/edit/:record2_id",
        name: "PatientRecord2Edit",
        component: PatientRecord2Edit
      },
      {
        // 利用者 記録を提出 編集
        path: "record2/editquick/:record2_id",
        name: "PatientRecord2EditFocus",
        component: PatientRecord2Edit,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 利用者 経過観察
        path: "progress",
        name: "PatientProgress",
        component: PatientProgress
      },
      {
        // 24時間体制 一覧
        path: "hour24",
        name: "PatientHour24List",
        component: PatientHour24List
      },
      {
        // 24時間体制 登録
        path: "hour24/regist/:fulltimeid",
        name: "PatientHour24Regist",
        component: PatientHour24Regist
      },
      {
        // 利用者 自費
        path: "selfburden",
        name: "PatientSelfburden",
        component: PatientSelfburden
      },
      {
        // 利用者 自費編集
        path: "selfburden/edit/:selfburdenid",
        name: "PatientSelfburdenEdit",
        component: PatientSelfburdenEdit
      },
      {
        // 利用者 自費テンプレート編集
        path: "selfburden/template/edit/:selfburdentemplateid",
        name: "PatientSelfburdenTemplateEdit",
        component: PatientSelfburdenTemplateEdit
      },
      {
        // 利用者 ADL評価一覧
        path: "adl",
        name: "PatientAdlList",
        component: PatientAdlList
      },
      {
        // 利用者 ADL評価作成
        path: "adl/edit/:adlid",
        name: "PatientAdlEdit",
        component: PatientAdlEdit
      },
      {
        // 利用者 ADL評価確認
        path: "adl/confirmation/:adlid",
        name: "PatientAdlConfirmation",
        component: PatientAdlConfirmation
      },
      {
        // 利用者 ADL評価確認（看護記録I）
        path: "adl/confirmationVnrecord/:vnrecordid",
        name: "PatientAdlConfirmationVnrecord",
        component: PatientAdlConfirmation
      },
      {
        // グループ設定
        path: "groupsetting",
        name: "PatientGroupSetting",
        component: PatientGroupSetting
      },
      {
        // 利用者 ファイル登録一覧
        path: "file",
        name: "PatientFiles",
        component: PatientFiles
      },
      ////// その他の情報の入力／確認 ///////
      {
        // 利用者 看護記録1編集
        path: "vnrecord/edit/:vnrecord_id",
        name: "PatientVnrecordEdit",
        component: PatientVnrecordEdit
      },
      {
        // 利用者 月次報告書作成
        path: "report/edit/:reportid",
        name: "PatientReportEdit",
        component: PatientReportEdit
      },
      {
        // 利用者 情報提供書作成
        path: "provision/edit/:provisionreportid",
        name: "ProvisionEdit",
        component: ProvisionEdit
      },
      {
        // 利用者 看護計画書編集
        path: "plan/edit/:vnplanid",
        name: "PatientPlanEdit",
        component: PatientPlanEdit
      },
      {
        // 服薬管理表
        path: "medicinetaking",
        name: "PatientMedicineTaking",
        component: PatientMedicineTaking
      },
      {
        // 服薬管理表編集
        path: "medicinetaking/edit/:medicine_id",
        name: "PatientMedicineTakingEdit",
        component: PatientMedicineTakingEdit
      },
      {
        // 利用者 サービス確認書作成
        path: "service/edit/:servicereportid",
        name: "PatientServiceEdit",
        component: PatientServiceEdit
      },
      {
        // 利用者 褥瘡編集
        path: "bedsore/edit/:bedsore_plan_id",
        name: "PatientBedsoreEdit",
        component: PatientBedsoreEdit
      },
      {
        // 利用者 看護サマリ
        path: "caresummary/edit/:care_summary_id",
        name: "PatientCareSummaryEdit",
        component: PatientCareSummaryEdit
      },
      {
        // 利用者 事故/ヒヤリハット報告書作成
        path: "occur/edit/:occurid",
        name: "PatientOccurEdit",
        component: PatientOccurEdit
      },
      {
        // 会議録作成
        path: "conference/edit/:conferenceid",
        name: "PatientConferenceEdit",
        component: PatientConferenceEdit
      },
      {
        // 書類確認
        path: "document",
        name: "PatientDocumentList",
        component: PatientDocumentList
      },
      {
        // ケアプラン
        path: "careplan",
        name: "PatientCareplan",
        component: PatientCareplan
      },
      {
        // 利用者 PDFリンクメール
        path: "pdflink",
        name: "PatientPdfLinkList",
        component: PatientPdfLinkMailList
      },
      {
        // PDFリンクメール編集
        path: "pdflink/edit/:pdf_link_id",
        name: "PatientPdfLinkEdit",
        component: PatientPdfLinkMailEdit
      },
      {
        // PDFリンクメール詳細・再送信
        path: "pdflink/detail/:pdf_link_id",
        name: "PatientPdfLinkDetail",
        component: PatientPdfLinkMailDetail
      },
      {
        // 書類グループ
        path: "report-group",
        name: "PatientReportGroup",
        redirect: "report-group/vnrecord",
        component: ReportGroup,
        children: [
          {
            // 利用者 看護記録1リスト
            path: "vnrecord",
            name: "PatientVnrecordList",
            component: PatientVnrecordList
          },
          {
            // 利用者 月次報告書一覧
            path: "report",
            name: "PatientReportList",
            component: PatientReportList
          },
          {
            // 利用者 看護計画書リスト
            path: "plan",
            name: "PatientPlanList",
            component: PatientPlanList
          },
          {
            // 利用者 サービス確認書一覧
            path: "service",
            name: "PatientServiceList",
            component: PatientServiceList
          },
          {
            // 利用者 褥瘡リスト
            path: "bedsore",
            name: "PatientBedsoreList",
            component: PatientBedsoreList
          },
          {
            // 利用者 看護サマリ一覧
            path: "caresummary",
            name: "PatientCareSummaryList",
            component: PatientCareSummaryList
          },
          {
            // 利用者 事故/ヒヤリハット報告書一覧
            path: "occur",
            name: "PatientOccurList",
            component: PatientOccurList
          },
          {
            // 会議録一覧
            path: "conference",
            name: "PatientConferenceList",
            component: PatientConferenceList
          },
          {
            // 利用者 情報提供書一覧
            path: "provision",
            name: "PatientProvisionList",
            component: PatientProvisionList
          }
        ]
      },
      {
        path: "vnrecord",
        redirect: "report-group/vnrecord"
      },
      {
        // 利用者 月次報告書一覧（別タブ）
        path: "report",
        name: "PatientReportListFocus",
        component: PatientReportList,
        meta: { isNoCommonArea: 1 }
      },
      {
        // 利用者 看護計画書一覧（別タブ）
        path: "plan",
        name: "PatientPlanListFocus",
        component: PatientPlanList,
        meta: { isNoCommonArea: 1 }
      },
      {
        path: "service",
        redirect: "report-group/service"
      },
      {
        path: "bedsore",
        redirect: "report-group/bedsore"
      },
      {
        path: "caresummary",
        redirect: "report-group/caresummary"
      },
      {
        path: "occur",
        redirect: "report-group/occur"
      },
      {
        path: "conference",
        redirect: "report-group/conference"
      },
      {
        // 利用者 情報提供書一覧（別タブ）
        path: "provision",
        name: "PatientProvisionListFocus",
        component: PatientProvisionList,
        meta: { isNoCommonArea: 1 }
      }
    ]
  },
  {
    // 利用者 経過観察印刷画面
    path: "/progress/print/:id",
    name: "PrintProgress",
    component: PrintProgress,
    meta: { isNoCommonArea: 1 }
  },
  {
    // ダッシュボード
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    // ダッシュボード 統計 保険・指示書群・その他加算群
    path: "/dashboard/statistics",
    name: "DashboardStatistics",
    component: DashboardStatistics
  },
  {
    // ダッシュボード 帳票
    path: "/dashboard/report",
    name: "DashboardReport",
    component: DashboardReport
  },
  {
    // ダッシュボード 表出力
    path: "/dashboard/tables",
    name: "DashboardTables",
    component: DashboardTables,
    meta: { isNotNormal: 1 }
  },
  {
    // ダッシュボード ケアプラン
    path: "/dashboard/careplan",
    name: "Careplan",
    component: Careplan
  },
  {
    // 書類グループ
    path: "/unsubmit",
    name: "UnsubmitList",
    component: UnsubmitList
  },
  {
    // 24時間体制 利用者一覧
    path: "/hour24/list",
    name: "Hour24List",
    component: Hour24List
  },
  {
    // 24時間体制 ボード
    path: "/hour24/board/",
    name: "Hour24Board",
    component: Hour24Board
  },
  {
    // 掲示板 トピック一覧
    path: "/bbs",
    name: "Bbs",
    component: Bbs
  },
  {
    // 掲示板 トピック管理
    path: "/bbs/notice/:bbsid",
    name: "BbsNotice",
    component: BbsNotice
  },
  {
    // 掲示板 トピック管理（別タブ）
    path: "/bbs/notice-sep/:bbsid",
    name: "BbsNoticeSep",
    component: BbsNotice,
    meta: { isNoCommonArea: 1 }
  },
  {
    // 掲示板 トピック詳細
    path: "/bbs/detail/:bbsid",
    name: "BbsDetail",
    component: BbsDetail
  },
  {
    // シフト登録
    path: "/shift",
    name: "StaffShift",
    component: StaffShift
  },
  {
    // レセプト 確定状況一覧
    path: "/reciept",
    name: "Reciept",
    component: Reciept,
    meta: { isNotNormal: 1 }
  },
  {
    // レセプト 個人
    path: "/reciept-patient",
    name: "PatientRecieptDetail",
    component: PatientRecieptDetail,
    meta: { isNotNormal: 1 }
  },
  {
    // レセプト 個人（別タブ）
    path: "/reciept-patient/quick",
    name: "PatientRecieptDetailFocus",
    component: PatientRecieptDetail,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  // Old訪問記録情報
  {
    // 訪問記録情報
    path: "/officeinvoice",
    name: "OldOfficeInvoice",
    component: OldOfficeInvoice
  },
  {
    // 訪問記録情報詳細
    path: "/officeinvoice/detail",
    name: "OldOfficeInvoiceDetail",
    component: OldOfficeInvoiceDetail
  },
  {
    // 訪問記録情報詳細
    path: "/officeinvoice/detail/staff",
    name: "OldOfficeInvoiceStaff",
    component: OldOfficeInvoiceStaff
  },
  {
    // BIホーム画面
    path: "/office_aggregate/bi_home",
    name: "BiHome",
    component: BiHome,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 訪問記録情報
    path: "/office_aggregate/officeinvoice",
    name: "OfficeInvoice",
    component: OfficeInvoice,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 訪問記録情報詳細
    path: "/office_aggregate/officeinvoice/detail",
    name: "OfficeInvoiceDetail",
    component: OfficeInvoiceDetail,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 訪問記録情報詳細
    path: "/office_aggregate/officeinvoice/detail/staff",
    name: "OfficeInvoiceStaff",
    component: OfficeInvoiceStaff,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // レセプト売上情報
    path: "/office_aggregate/receipt_sales",
    name: "ReceiptSales",
    component: ReceiptSales,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // レセプト売上情報詳細
    path: "/office_aggregate/receipt_sales/detail",
    name: "ReceiptSalesDetail",
    component: ReceiptSalesDetail,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // レセプト売上情報利用者詳細
    path: "/office_aggregate/receipt_sales/detail/patient",
    name: "ReceiptSalesPatient",
    component: ReceiptSalesPatient,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 利用者数情報
    path: "/office_aggregate/patient_number",
    name: "PatientNumber",
    component: PatientNumber,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 利用者継続率
    path: "/office_aggregate/patient_continuity_rate",
    name: "PatientContinuityRate",
    component: PatientContinuityRate,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 指示書枚数
    path: "/office_aggregate/indicate_number",
    name: "IndicateNumber",
    component: IndicateNumber,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 勤務時間単価
    path: "/office_aggregate/unit_cost",
    name: "UnitCost",
    component: UnitCost,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // 勤務時間単価詳細
    path: "/office_aggregate/unit_cost/detail",
    name: "UnitCostDetail",
    component: UnitCostDetail,
    meta: { isNotNormal: 1, isNoCommonArea: 1 }
  },
  {
    // レセプト 事前チェック
    path: "/reciept/precheck",
    name: "PreCheck",
    component: PreCheck,
    meta: { isNotNormal: 1, isNoCommonArea: 1,title:"レセプト事前チェック" }
  },
  {
    // 事業所訪問記録情報
    path: "/officeinvoice",
    name: "OfficeInvoice",
    component: OfficeInvoice,
    meta: { isNotNormal: 1 }
  },
  {
    // 事業所訪問記録情報詳細
    path: "/officeinvoice/detail",
    name: "OfficeInvoiceDetail",
    component: OfficeInvoiceDetail,
    meta: { isNotNormal: 1 }
  },
  {
    // 事業所訪問記録情報詳細
    path: "/officeinvoice/detail/staff",
    name: "OfficeInvoiceStaff",
    component: OfficeInvoiceStaff,
    meta: { isNotNormal: 1 }
  },
  {
    // 共有ファイル(トップ)
    path: "/sharefile",
    name: "ShareFile",
    component: ShareFile
  },
  {
    // 共有ファイル(契約)
    path: "/sharefile/list",
    name: "ShareFiles",
    component: ShareFiles
  },
  {
    // 共有ファイル(利用者)
    path: "/sharefile/patient",
    name: "ShareFilePatient",
    component: ShareFilePatient
  },
  {
    // マスタメンテ
    path: "/master",
    name: "Mastermaintenance",
    alias: "/master/list",
    component: Mastermaintenance,
    meta: { isNotNormal: 1 }
  },
  {
    // 事業所マスタ
    path: "/master/office",
    component: MasterBase,
    meta: { masterMenuId: 1 },
    children: [
      {
        //マスタメンテ 契約情報
        path: "agree_inq",
        name: "AgreeInq",
        alias: "",
        component: AgreeInq,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 事業所一覧
        path: "list",
        name: "MastermaintenanceOffice",
        component: MastermaintenanceOffice,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 事業所履歴一覧
        path: "history/:id",
        name: "MastermaintenanceOfficeHistory",
        component: MastermaintenanceOfficeHistory,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 事業所編集
        path: "edit/:id",
        name: "MastermaintenanceOfficeEdit",
        component: MastermaintenanceOfficeEdit,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 事業所編集
        path: "new",
        name: "MastermaintenanceOfficeNew",
        component: MastermaintenanceOfficeNew,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 事業所履歴一覧
        path: "history/edit/:id",
        name: "MastermaintenanceOfficeHistoryEdit",
        component: MastermaintenanceOfficeHistoryEdit,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 通知設定
        path: "notice-setting",
        name: "NoticeSetting",
        component: NoticeSetting,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 職員情報一覧
        path: "staff",
        name: "StaffList",
        alias: "staff/list",
        component: StaffList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 職員シフト登録
        path: "staff/shift/:office_id/:staff_id",
        name: "MasterStaffShift",
        component: MasterStaffShift,
        meta: { isNotNormal: 1, isNotMasterHeader: 1 }
      },
      {
        // マスタメンテ 職員情報履歴一覧
        path: "staff/history",
        name: "StaffHistory",
        component: StaffHistory,
        meta: { isNotNormal: 1, isNotMasterHeader: 1 }
      },
      {
        // マスタメンテ 職員情報登録
        path: "staff/edit/:id",
        name: "StaffEdit",
        component: StaffEdit,
        meta: { isNotNormal: 1, isNotMasterHeader: 1 }
      }
    ]
  },
  {
    // 連携機関マスタ
    path: "/master/relation",
    component: MasterBase,
    meta: { masterMenuId: 2 },
    children: [
      {
        // マスタメンテ 関連機関情報一覧
        path: "corp-entry",
        name: "CorpEntry",
        alias: "",
        component: CorpEntry,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 関連機関情報登録
        path: "corp-entry/new",
        name: "CorpNew",
        component: CorpNew,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 関連機関情報編集
        path: "corp-edit/:id",
        name: "CorpEdit",
        component: CorpEdit,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 医療機関情報一覧
        path: "medical-entry",
        name: "MedicalEntry",
        component: MedicalEntry,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 医療機関情報登録
        path: "medical-entry/new",
        name: "MedicalNew",
        component: MedicalNew,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 医療機関情報編集
        path: "medical-edit/:id",
        name: "MedicalEdit",
        component: MedicalEdit,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 同一建物情報一覧
        path: "facility/list",
        name: "FacilityList",
        component: FacilityList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 同一建物情報登録
        path: "facility/edit/:id",
        name: "FacilityEdit",
        component: FacilityEdit,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 訪問看護ステーション一覧
        path: "care-station-entry",
        name: "CareStationEntry",
        component: CareStationEntry,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 訪問看護ステーション登録
        path: "care-station/new",
        name: "CareStationNew",
        component: CareStationNew,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 訪問看護ステーション編集
        path: "care-station-edit/:id",
        name: "CareStationEdit",
        component: CareStationEdit,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 保険者情報一覧
        path: "insurer-entry",
        name: "InsurerEntry",
        component: InsurerEntry,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 保険者情報登録
        path: "insurer/new",
        name: "InsurerNew",
        component: InsurerNew,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 保険者情報編集
        path: "insurer-edit/:id",
        name: "InsurerEdit",
        component: InsurerEdit,
        meta: { isNotNormal: 1 }
      }
    ]
  },
  {
    // 看護系マスタ
    path: "/master/nursing",
    component: MasterBase,
    meta: { masterMenuId: 3 },
    children: [
      {
        // マスタメンテ 看護計画用問題点・解決策一覧
        path: "nursingplan/list",
        name: "MastermaintenanceNursingPlanList",
        alias: "",
        component: MastermaintenanceNursingPlanList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 看護内容登録
        path: "nursing-content",
        name: "NursingContent",
        component: NursingContent,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 観察項目カテゴリ登録
        path: "observeitem/category/list",
        name: "ObserveItemCategoryList",
        component: ObserveItemCategoryList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 観察項目登録
        path: "adl_entry",
        name: "AdlContentList",
        component: AdlContentList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ ADL評価項目登録
        path: "adl_eval_entry",
        name: "AdlItemList",
        component: AdlItemList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 評価項目基準登録
        path: "std_eval_entry",
        name: "StdEvalItemList",
        component: StdEvalItemList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 服薬情報登録
        path: "medicine/list",
        name: "MedicineTakingList",
        component: MedicineTakingList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 公費情報
        path: "public_money_kind",
        name: "PublicMoneyKindEntry",
        component: PublicMoneyKindEntry,
        meta: { isNotNormal: 1 }
      }
    ]
  },
  {
    // 選択肢マスタ
    path: "/master/choice",
    component: MasterBase,
    meta: { masterMenuId: 4 },
    children: [
      {
        // マスタメンテ 続柄一覧
        path: "relation/list",
        name: "RelationList",
        alias: "",
        component: RelationList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ グループ登録一覧
        path: "group/list",
        name: "MastermaintenanceGroupList",
        component: MastermaintenanceGroupList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ ファイルタグ一覧
        path: "filetag/list",
        name: "FileTagList",
        component: FileTagList,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 自費サービスリスト
        path: "own-expense-service",
        name: "OwnExpenseService",
        component: OwnExpenseService,
        meta: { isNotNormal: 1 }
      }
    ]
  },
  {
    // データマスタ
    path: "/master/data",
    component: MasterBase,
    meta: { masterMenuId: 5 },
    children: [
      {
        // マスタメンテ 標準CSV出力
        path: "csv/normal/output",
        name: "MastermaintenanceCsvNormalOutput",
        alias: "",
        component: MastermaintenanceCsvNormalOutput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 利用者請求情報入出力
        path: "patientinvoice",
        name: "PatientInvoice",
        component: PatientInvoice,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 記録書II一括出力
        path: "record2_bulk_output",
        name: "Record2BulkOutput",
        component: Record2BulkOutput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 情報連携の標準仕様 関連機関一覧
        path: "info_coord",
        name: "InfoCoord",
        component: InfoCoord,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 情報連携の標準仕様 個別関連機関
        path: "info_coord/:id",
        name: "InfoCoordIO",
        component: InfoCoordIO,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ すこやかサン連携CSV出力
        path: "sukoyakasan/link",
        name: "SukoyakasanLink",
        component: SukoyakasanLink,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ ケアカルテ（ちょうじゅ）CSV入力
        path: "choju_csv_input",
        name: "CsvInput",
        component: CsvInput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ ケアカルテ（ちょうじゅ）CSV出力
        path: "csv/chouju/output",
        name: "MastermaintenanceCsvChoujuOutput",
        component: MastermaintenanceCsvChoujuOutput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ ワイズマン連携WTF入力
        path: "wiseman/import",
        name: "WisemanImport",
        component: WisemanImport,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ ワイズマン連携CSV出力
        path: "csv-output-wiseman-receipt",
        name: "CsvOutputWiseman",
        component: CsvOutputWiseman,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ Excel入力
        path: "excel/input",
        name: "ExcelInput",
        component: ExcelInput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ Excel出力
        path: "excel/output",
        name: "ExcelOutput",
        component: ExcelOutput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 医療保険オンライン請求Excel入力
        path: "med-transmission-excel/input",
        name: "MedTransmissionExcelInput",
        component: MedTransmissionExcelInput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 医療保険オンライン請求Excel出力
        path: "med-transmission-excel/output",
        name: "MedTransmissionExcelOutput",
        component: MedTransmissionExcelOutput,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 職員一括連携
        path: "staff/link",
        name: "BatchStaffLink",
        component: BatchStaffLink,
        meta: { isNotNormal: 1 }
      }
    ]
  },
  {
    // ログ
    path: "/master/log",
    component: MasterBase,
    meta: { masterMenuId: 6 },
    children: [
      {
        // マスタメンテ オペレーションログ
        path: "operation",
        name: "MastermaintenanceOperationLog",
        alias: "",
        component: MastermaintenanceOperationLog,
        meta: { isNotNormal: 1 }
      },
      {
        // マスタメンテ 利用者/職員の復元
        path: "user_recommendation",
        name: "RestoreMasterPatient",
        component: RestoreMasterPatient,
        meta: { isNotNormal: 1 }
      }
    ]
  },
  {
    // FAQお問合せ
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    // FAQお問合せ詳細
    path: "/contact/show/:id",
    name: "ContactDetail",
    component: ContactDetail
  },
  {
    // お問合せ一覧
    path: "/contact/history",
    name: "ContactHistory",
    component: ContactHistory
  },
  {
    // お問合せ作成
    path: "/contact/new/:id?",
    name: "ContactNew",
    component: ContactNew
  },
  {
    // FAQ一覧
    path: "/contact/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    // FAQ詳細
    path: "/contact/faq/show/:id",
    name: "FAQDetail",
    component: FAQDetail
  },
  {
    // iBow勤怠連携
    path: "/ibowkintai/link",
    name: "IbowKintaiLink",
    component: IbowKintaiLink
  },
  {
    // AIお勧め検索 病名一覧
    path: "/ai/diseases",
    name: "AiRecommendDisease",
    component: AiRecommendDisease,
    meta: { isNoCommonArea: 1 }
  },
  {
    // 存在しないパス
    path: "/*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.name != from.name) {
        return { x: 0, y: 0 };
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to) {
    document.title = to?.meta?.title || document.title;
  }
  next();
});
export default router;
