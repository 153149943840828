















































































































































































































































































































































































































































































































import { Component, Prop, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import AppPlaces from "#/components/AppPlaces.vue";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import {
  RelationInstitution,
  DefaultRelationInstitution,
  Personnel,
  DefaultPersonnel,
  PersonnelContact,
  DefaultPersonnelContact
} from "#/model/uniquemaster";
import { VForm } from "@/types";
import EditMixin from "@/mixins/editMixin";
import * as constant from "#/const";
import { DataTableHeader } from "vuetify";
import * as appCopy from "#/utility/appCopy";

interface DispRelationInstitution
  extends Omit<RelationInstitution, "personnels"> {
  personnels: DispPersonnel[];
}
interface DispPersonnel extends Personnel {
  index?: number;
}
interface DispPersonnelContact extends PersonnelContact {
  index?: number;
}
const DefaultDispPersonnel = (): DispPersonnel => ({
  ...DefaultPersonnel(),
  index: 0
});
const DefaultDispPersonnelContact = (): DispPersonnelContact => ({
  ...DefaultPersonnelContact(),
  index: 0
});

@Component({
  components: {
    AppPlaces,
    OfficeSelectInput
  }
})
export default class CorpForm extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  EditMixin
) {
  @Ref("form") private readonly form!: VForm;
  @Ref("dialog") private readonly dialogForm!: VForm;

  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: null }) corpId!: number;

  // マスタメンテ以外からの操作の場合はfalse
  @Prop({ default: true }) isMaster!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return DefaultRelationInstitution();
    }
  })
  content!: DispRelationInstitution;

  public cloneContent?: DispRelationInstitution = this.content;

  private clonePersonnel?: DispPersonnel[] = [];

  private newContact?: DispPersonnelContact = DefaultDispPersonnelContact();

  private newPersonnel?: DispPersonnel = DefaultDispPersonnel();

  private valid = true;

  private relationInstitutionsList: RelationInstitution[] = [];

  private editingPersonnel: DispPersonnel = DefaultDispPersonnel();

  private isOpenPersonnelDialog = false;

  private isEdit = false;

  private editItemIndex = -1;

  private personnelLength = 0;

  private contactCnt = 0;

  private personnelHeaders: DataTableHeader[] = [
    {
      text: "担当課",
      value: "section_name",
      width: "150px"
    },
    {
      text: "担当者名",
      value: "full_name"
    },
    {
      text: "ふりがな",
      value: "furigana"
    },
    {
      text: "ステータス",
      value: "status"
    },
    {
      text: "留意事項",
      value: "remarks"
    },
    {
      text: "",
      value: "edit",
      width: "25px"
    }
  ];

  //自費サブカテゴリヘッダー
  private subHeaders: DataTableHeader[] = [
    {
      text: "連絡先",
      value: "contact",
      filterable: false,
      sortable: false
    }
  ];
  //選択済みの適応事業所
  public selectedOffice: number[] = [];

  get filteredRelationInstitutionsList() {
    const cloned = JSON.parse(
      JSON.stringify(this.relationInstitutionsList)
    ) as RelationInstitution[];
    return (
      cloned.filter(
        x => x.support_office_div == this.cloneContent?.support_office_div
      ) || []
    );
  }

  get getNameLabel() {
    switch (this.cloneContent?.support_office_div) {
      case 1:
        return "*居宅介護支援事業所名";
      case 2:
        return "*情報提供先名称";
      case 3:
        return "*地域包括支援センター名";
      case 9:
        return "*関連機関名";
      default:
        return "*居宅介護支援事業所名";
    }
  }

  // 削除ボタン表示フラグ
  private get showDelete(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_RELATION_INSTITUTION,
      constant.AGREEMENT.FUNCTION_DIV_DELETE
    );
  }

  // 担当者追加ボタン表示フラグ
  private get showAddPersonel(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_PERSONEL,
      constant.AGREEMENT.FUNCTION_DIV_INSERT
    );
  }

  // 担当者削除ボタン表示フラグ
  private get showDeletePersonel(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_PERSONEL,
      constant.AGREEMENT.FUNCTION_DIV_DELETE
    );
  }

  created() {
    this.cloneContent = this.content;
    this.clonePersonnel = this.content.personnels;
    if (this.corpId > 0) {
      this.getRelationInstitution();
    } else {
      this.setLoaded();
    }
    this.fetchCorpList();
  }

  private getStatus(index: number) {
    if (!index) return;
    return this.masters.staff_statuses.filter(item => {
      return item.value === index;
    })[0].text;
  }

  private getContactType(type: number) {
    if (!type) return;
    return this.contactTypes.filter(item => {
      return type === item.value;
    })[0].text;
  }

  private fetchCorpList(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/relationinstitutions/get",
      {},
      res => {
        this.relationInstitutionsList = res.data.relation_institutions;
        if (this.cloneContent && this.clonePersonnel) {
          let loopCnt = 0;
          this.cloneContent.personnels.forEach(personnel => {
            personnel.index = loopCnt; // 既に追加されている担当者の数(expand対応)
            loopCnt++;
          });
          this.personnelLength = this.cloneContent.personnels.length;
        }
      }
    );
  }

  private getRelationInstitution() {
    this.postJsonCheck(
      window.base_url + "/api/master/relationinstitution/get",
      {
        relation_institution_id: Number(this.corpId)
      },
      res => {
        this.cloneContent = { ...res.data.relation_institution };
        this.cloneContent?.relation_institution_offices?.forEach(office => {
          if (office.office_id) {
            this.selectedOffice.push(office.office_id);
          }
        });

        let headPersonnel = "";
        if (this.cloneContent && this.cloneContent?.personnels) {
          headPersonnel = "　" + this.cloneContent?.personnels[0].full_name; //異なる宛名連結用先頭の医師名
        }

        if (this.cloneContent) {
          // 送付状の住所が登録先と異なる宛名、ふりがなが空の場合、機関名、ふりがなから転記
          if (!this.cloneContent?.letter_name) {
            this.cloneContent.letter_name =
              this.cloneContent.name + headPersonnel;
          }
          if (!this.cloneContent?.letter_furigana) {
            this.cloneContent.letter_furigana = this.cloneContent.furigana;
          }
        }
        this.setLoaded();
      }
    );
  }

  public async saveRelationInstitution(): Promise<boolean> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return false;
    }
    if (
      this.isMaster &&
      (!this.cloneContent?.personnels ||
        this.cloneContent.personnels.length < 1)
    ) {
      if (
        !(await this.$openConfirm("担当者が未登録ですがこのまま保存しますか？"))
      ) {
        return false;
      }
    } else {
      if (!(await this.$openConfirm("保存しますか？"))) {
        return false;
      }
    }
    if (this.cloneContent) {
      this.cloneContent.relation_institution_offices = this.selectedOffice.map(
        off => {
          return {
            id: 0,
            relation_institution_id: 0,
            office_id: off
          };
        }
      );

      // 保存直前、居宅or地域包括かつ主たる事業所以外は事業所番号を空にする
      if (
        (this.cloneContent.support_office_div != 1 &&
          this.cloneContent.support_office_div != 3) ||
        this.cloneContent.office_div != 1
      ) {
        this.cloneContent.office_code = "";
      }
    }

    this.postJsonCheck(
      window.base_url + "/api/master/relationinstitution/save",
      {
        relation_institution: this.cloneContent
      },
      () => {
        this.setNoEditMode();
        if (this.isMaster) {
          this.$router.push({ name: "CorpEntry" });
        }
      }
    );
    return true;
  }

  private async deleteRelationInstitution() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/master/relationinstitution/delete",
        {
          relation_institution: this.cloneContent
        },
        () => {
          this.setNoEditMode();
          this.$router.push({ name: "CorpEntry" });
        }
      );
    }
  }
  // 連絡先追加
  private addNewContact(personnel: DispPersonnel) {
    if (personnel) {
      if (this.newContact && this.clonePersonnel) {
        if (personnel.id) {
          this.newContact.personnel_id = Number(personnel.id);
        }

        this.contactCnt++; // 重複しないようインクリメント
        this.newContact.index = this.contactCnt;

        if (!personnel.personnel_contacts) {
          personnel.personnel_contacts = [];
        }
        personnel.personnel_contacts.push({ ...this.newContact });
      }
      this.newContact = DefaultDispPersonnelContact();
    }
  }

  // 担当者追加
  private addNewPersonnel() {
    if (this.newPersonnel && this.cloneContent) {
      this.newPersonnel.is_empty = 1;
      this.personnelLength++;
      this.newPersonnel.index = this.personnelLength; // 既に追加されている担当者の数
      this.newPersonnel.relation_institution_name = this.cloneContent.name;
      this.editingPersonnel = appCopy.deepCopy(this.newPersonnel);
      this.addNewContact(this.editingPersonnel); // 連絡先項目を追加
      this.isEdit = false;
      this.isOpenPersonnelDialog = true;
      // if (!this.dialogForm.validate) this.dialogForm.resetValidation();
    }
    this.newPersonnel = DefaultDispPersonnel();
  }

  // 担当者登録
  private savePersonnel() {
    if (!this.dialogForm.validate()) {
      return;
    }
    if (!this.isEdit && this.newPersonnel && this.cloneContent) {
      /* 追加 */
      this.newPersonnel.is_empty = 1;
      this.editingPersonnel.relation_institution_name = this.cloneContent.name;

      this.cloneContent.personnels?.push(this.editingPersonnel);

      // 連絡先が入力されているもののみ保存
      const contactList = this.editingPersonnel.personnel_contacts.filter(
        contact => {
          return contact.contact_type !== 0 || contact.contact_info !== "";
        }
      );
      this.editingPersonnel.personnel_contacts = contactList;

      this.isOpenPersonnelDialog = false;
    } else if (this.isEdit && this.cloneContent) {
      /* 編集 */
      // インデックス見つからなかった場合は追加する
      if (this.editItemIndex < 0) {
        if (this.newPersonnel) this.newPersonnel.is_empty = 1;
        this.editingPersonnel.relation_institution_name = this.cloneContent.name;
        this.cloneContent.personnels?.push(this.editingPersonnel);
        this.isOpenPersonnelDialog = false;
      }

      // 連絡先が入力されているもののみ保存
      const contactList = this.editingPersonnel.personnel_contacts.filter(
        contact => {
          return contact.contact_type !== 0 || contact.contact_info !== "";
        }
      );
      this.editingPersonnel.personnel_contacts = contactList;

      Object.assign(
        this.cloneContent.personnels[this.editItemIndex],
        this.editingPersonnel
      );

      this.isOpenPersonnelDialog = false;
    }
  }

  // 担当者編集
  private isEditPersonnel(rowItem: DispPersonnel) {
    // インデックス取得
    this.editItemIndex = this.cloneContent?.personnels.indexOf(rowItem) ?? -1;
    this.editingPersonnel = appCopy.deepCopy(rowItem);

    this.contactCnt = this.editingPersonnel.personnel_contacts.length;
    this.addNewContact(this.editingPersonnel); // 連絡先項目を追加

    this.isEdit = true;
    this.isOpenPersonnelDialog = true;
  }

  private requiredStr(str: string): boolean | string {
    return this.required(str, "必須です");
  }

  private requiredSupportOfficeDiv(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.support_office_div > 0) {
      return true;
    }
    return "必須です";
  }

  private requiredDocumentForm(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.document_form > 0) {
      return true;
    }
    return this.required("", "必須です");
  }

  private requiredOfficeDiv(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (
      this.cloneContent.support_office_div == 1 ||
      this.cloneContent.support_office_div == 3
    ) {
      if (this.cloneContent.office_div > 0) {
        return true;
      }
      return "必須です";
    }
    return true;
  }

  private requiredName(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.name.length > 0) {
      return true;
    }
    return this.required(this.cloneContent.name, "必須です");
  }

  private ruleOfficeCode(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (
      this.cloneContent.office_div == 1 &&
      (this.cloneContent.support_office_div == 1 ||
        this.cloneContent.support_office_div == 3)
    ) {
      if (this.cloneContent.office_code.length !== 10) {
        return "半角英数字10文字でご記入ください";
      }
      if (this.cloneContent.pref !== 0) {
        const prefName = this.prefName(this.cloneContent.pref);
        const prefCode = ("00" + this.cloneContent.pref).slice(-2);
        if (prefCode !== this.cloneContent.office_code.slice(0, 2)) {
          return `住所に${prefName}を選択している場合、先頭2文字は${prefCode}にしてください`;
        }
      }
    }
    return true;
  }

  private requiredFurigana(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.furigana.length > 0) {
      return true;
    }
    return this.required(this.cloneContent.furigana, "必須です");
  }

  private requiredParentRelation(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (
      this.cloneContent.office_div == 2 &&
      (this.cloneContent.support_office_div == 1 ||
        this.cloneContent.support_office_div == 3)
    ) {
      if (this.cloneContent.parent_relation_institution_id > 0) {
        return true;
      }
      return "必須です";
    }
    return true;
  }

  private requiredPersFullName(namePart: string): boolean | string {
    if (namePart.length > 0) {
      return true;
    }
    return this.required(
      namePart,
      "※担当者を追加される場合は担当者名も必須です。"
    );
  }
  private requiredPersFurigana(namePart: string): boolean | string {
    if (namePart.length > 0) {
      return true;
    }
    return this.required(
      namePart,
      "※担当者を追加される場合はふりがなも必須です。"
    );
  }

  //連絡先削除
  private async deleteConfirm(personnel: DispPersonnel, index: number) {
    if (!(await this.$openConfirm("連絡先を削除しますか？"))) {
      return;
    }
    personnel?.personnel_contacts?.splice(index, 1);
  }

  //担当者削除
  private async deletePersonnel() {
    if (!(await this.$openConfirm("担当者を削除しますか？"))) {
      return;
    }
    this.cloneContent?.personnels?.splice(this.editItemIndex, 1);
    this.isOpenPersonnelDialog = false;
  }

  // ダイアログのタイトル取得
  private get dialogTitle(): string {
    if (this.isEdit) {
      return "担当者を編集する";
    } else {
      return "担当者を追加する";
    }
  }

  private closeDialog() {
    this.isOpenPersonnelDialog = false;
  }

  // 入力値クリア
  public clear() {
    this.cloneContent = DefaultRelationInstitution();
    this.form.resetValidation();
  }

  @Watch("cloneContent", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
