/** 処理状態(レセプト事前チェックのライフサイクルが変わったので適切なのかわからない) */
export const ProcessState = {
  /** 未処理 */
  UNPROCESS: 1,
  /** 処理中 */
  PROCESSING: 2,
  /** 処理完了 */
  PROCESSED: 3,
  /** 中断中 */
  INTERRUPTING: 4,
  /** 中断完了 */
  INTERRUPTED: 5,
  /** 無効化 */
  DISABLED: 6
};

/* 同一建物内人数集計結果 */
export interface SameBuildingAggregation {
  /** 同一建物内人数集計結果キー情報 */
  key: SameBuildingAggregationKey;
  /** 利用者数 */
  patient_count: number;
}

/* 同一建物内人数集計結果キー情報 */
export interface SameBuildingAggregationKey {
  /** 訪問日 */
  visit_date: string;
  /** 同一建物ID */
  same_building_id: number;
  /** 集計グループ区分(基本、複数名イロハニ、難病等複数回) */
  group_div: number;
  /** 集計カウント区分(1回、2回、3回以上) */
  count_div: number;
}

/** レセプト事前チェック結果 */
export interface PreCheckResponse {
  /** 処理結果メッセージ */
  process_message: string;
  /** レセプト事前チェック結果 */
  check_responses: PreCheckResponseRecord[];
  /** 再登録成功実績カウント */
  register_succeeded_count: number;
  /** 再登録失敗実績カウント */
  register_failed_count: number;
}

/** レセプト事前チェック結果(各実績の情報) */
export interface PreCheckResponseRecord {
  /** 実績ID */
  id: number;
  /** 1:医療 2:精神 */
  mental_div: number;
  /** サービス提供日 */
  service_date: string;
  /** サービス提供開始時刻 */
  service_start_time: string;
  /** サービス提供終了時刻  */
  service_end_time: string;
  /** サービス提供時刻 */
  service_time: string;
  /** 同一建物名 */
  same_building_name: string;
  /** 利用者名 */
  patient_name: string;
  /** 利用者名ふりがな */
  patient_name_kana: string;
  /** 基本療養費区分（前） */
  remedy_div_before: number;
  /** 基本療養費区分（後）  */
  remedy_div_after: number;
  /** 同一日3人以上（前） */
  three_person_flg_before: number;
  /** 同一日3人以上（後）  */
  three_person_flg_after: number;
  /** 複数名訪問看護加算に関わる同一建物内人数(前) */
  accompanied_staff_threeperson_div_before: number;
  /** 複数名訪問看護加算に関わる同一建物内人数(前)  */
  accompanied_staff_threeperson_div_after: number;
  /** 難病等複数回訪問看護加算に関わる同一建物内人数(前) */
  times_threeperson_div_before: number;
  /** 難病等複数回訪問看護加算に関わる同一建物内人数(前)  */
  times_threeperson_div_after: number;
  /** 状態 0:変更なし 1:再登録成功 2:再登録失敗 */
  status: number;
  /** エラーメッセージ */
  error_message: string;
}


/** レセプト事前チェックの途中経過 */
export interface PreCheckState {
  /** ID */
  id: string;
  /** 作成職員ID */
  staff_id: number;
  /** 処理済みの件数 */
  number_processe: number;
  /** 総数 */
  total_count: number;
  /** 中断したかどうか */
  status_flag: number;
  /** ハンドラが反応しないことがあるので */
  time: string;
  /** Excelのパス */
  url: string;
  /** PreCheckId */
  pre_check_id: number;
}

/** レセプト事前チェックページの状態管理 */
export interface PreCheckPageState {
  /** 処理済みの件数 */
  number_processe: number;
  /** 総数 */
  total_count: number;
  /** 状態 */
  status_flag: number;
}

export const DefaultPreCheckPageState = (): PreCheckPageState => ({
  number_processe: 0,
  total_count: 0,
  status_flag: 1,
});
