

















































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { PatientInfo, DefaultReceiptSearchCondition } from "#/model/receipt";
import { ProcessState, PreCheckResponse } from "@/views/reciept/types";
import { COLLECTION_RECEIPT_PRE_CHECK } from "@/const/envFireStore";
import { PreCheckState } from "@/views/reciept/types";
import InfiniteLoading from "vue-infinite-loading";
import { PreCheckSameBuildingHistory } from "#/model/prechecksamebuilding";
import { DataTableHeader } from "vuetify/types/index";

@Component
export default class PreCheck extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  InfiniteLoading
) {
  /** 無限ローディング */
  @Ref("infiniteLoading")
  private readonly infiniteLoading!: InfiniteLoading;

  /** 読み込みページ数 */
  private page = 1;

  /** 一回で取得するデータ量 */
  private limit = 10;

  private histories: PreCheckSameBuildingHistory[] = []; //未読一覧

  private historyRecords: { [key: number]: PreCheckSameBuildingHistory } = {};

  /** テーブルのヘッダー */
  private header: DataTableHeader[] = [
    {
      text: "状態",
      value: "status_flag",
      align: "center",
      width: "120px",
      cellClass: "text-center",
    },
    {
      text: "処理対象年月",
      value: "yearmonth",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "処理日時",
      value: "date_processed",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "処理人数",
      value: "processed_count",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "対象人数",
      value: "total_count",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "処理結果",
      value: "action",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
  ];

  /** 実行回数カウント（新しくタブを開いてからの実行回数） */
  private ownCheckDone = false;

  /** 処理合計 */
  private total = 0;

  /** 処理カウント */
  private count = 0;

  /** 処理状態 */
  private processState = 1;

  /** タブ情報 */
  private tab = 0;

  /** 処理結果メッセージ */
  private message = "";

  /** 処理再開フラグ */
  private isRestart = false;

  private hint =
    "下記3点を満たすと処理の対象になります。\n1．レセプト処理画面で選択している利用者\n2．同一建物を設定した訪問看護記録書Ⅰが提出済み\n※訪問看護記録書Ⅰ＞お住まいの状況に関する項目＞住居環境＞施設説明に連携機関マスタに登録している同一建物を設定している\n3．登録または確定されている実績（精神科訪問看護を含む）\n※請求履歴確定は含まない";

  /** 事前チェック結果 */
  private checkResponses: PreCheckResponse[] = [];

  /** 選択された利用者一覧 */
  private selectPatients: PatientInfo[] = [];

  private unfilteredpatients: PatientInfo[] = [];

  /** firestoreに保存する条件 */
  private condition = DefaultReceiptSearchCondition();

  /** レセプト事前チェックのID */
  private preCheckId = -1;

  /** 表示用の変数 */
  private targetYearmonth = "";
  private totalCount = 0;
  private innerTotalCount = 0;
  private innerCount = 0;
  private unfilteredCount = 0;
  private innerState = 1;
  private isOwnCheck = false;
  private isStaffIdOK = true;

  // ダイアログ
  private isOpenDialog = false;
  private isContinue = false;

  /** リアルタイムアップデート用リスナーをデタッチする関数 */
  private detach = () => {
    return;
  };

  private statusText(item: PreCheckSameBuildingHistory): string {
    switch (item.status_flag) {
      case 0:
        return "処理中";
      case 1:
        if (this.isFiveMinutesPassed(item.date_processed)) {
          return "エラー";
        } else {
          return "処理中";
        }
      case 2:
        return "完了";
      case 3:
        return "中断";
      default:
        return "エラー";
    }
  }

  async created() {
    // 前画面でセットされた利用者と検索条件を取得

    const lsPatients = localStorage.getItem("PreCheckSelectPatients");
    const lsUfPatients = localStorage.getItem("PreCheckUnfilterPatients");
    const lsCondition = localStorage.getItem("PreCheckCondition");

    if (lsPatients !== null) {
      this.selectPatients = JSON.parse(lsPatients);
      this.innerTotalCount = this.selectPatients.filter(
        (sp: PatientInfo) => !sp.invoice_history && sp.medical
      ).length;
    }

    if (lsUfPatients !== null) {
      this.unfilteredpatients = JSON.parse(lsUfPatients);
      this.unfilteredCount = this.unfilteredpatients.filter(
        (sp: PatientInfo) => !sp.invoice_history
      ).length;
    }

    if (lsCondition !== null) {
      this.condition = JSON.parse(lsCondition);
      this.targetYearmonth = this.condition.search_cond.yearmonth;
      this.totalCount = this.condition.ids.length;
    }

    localStorage.removeItem("PreCheckSelectPatients");
    localStorage.removeItem("PreCheckCondition");
    this.collection = COLLECTION_RECEIPT_PRE_CHECK;

    // 遷移してきたときの初期値
    this.total = this.innerTotalCount;
    this.count = 0;

    // 対象利用者がいない場合は何もしない
    if (this.innerTotalCount === 0) {
      const res = await this.openDialog();
      if (!res) return;
    }
  }
  mounted() {
    // リアルタイムアップデート用リスナーを作成
    this.createListener();
  }

  beforeDestroy() {
    // リアルタイムアップデート用リスナーをデタッチ
    this.detach();
  }
  /** リアルタイムアップデート用リスナーの作成 */
  private createListener() {
    const docId = this.createDocumentId(this.condition.search_cond.office_id);
    this.detach = this.getListner(
      (query) => {
        return query.where("id", "==", docId);
      },
      (docChange) => {
        const stateData = docChange.doc.data() as PreCheckState;
        stateData.id = docChange.doc.id;

        // 5分以上前の書き込みなら無いものとして扱う（安全装置）
        const isFiveMinutesPassed = this.isFiveMinutesPassed(stateData.time);
        if (isFiveMinutesPassed) {
          return;
        }

        // 自分が実行しているかどうか(再開できるかどうか)
        this.isOwnCheck = stateData.pre_check_id == this.preCheckId;

        // この処理者が中断できるチェックかどうか
        this.isStaffIdOK = stateData.staff_id == this.loginUser.staff_id;

        if (stateData.url != "") {
          this.page = 0;
          this.getPreCheckHistory();
        }

        if (this.processState == ProcessState.DISABLED) {
          return;
        }

        switch (stateData.status_flag) {
          case 1: //処理中
            this.preCheckId = this.ownCheckDone ? stateData.pre_check_id : 0;
            this.processState = ProcessState.PROCESSING;
            this.count = stateData.number_processe;
            this.total = stateData.total_count;
            break;
          case 2: //処理完了
            this.processState = this.isOwnCheck
              ? ProcessState.DISABLED
              : this.innerState;

            if (!this.isOwnCheck) {
              this.backState();
            }
            this.page = 0;
            this.getPreCheckHistory();
            break;
          case 3: //処理中断
            this.processState = this.isOwnCheck
              ? ProcessState.INTERRUPTED
              : this.innerState;

            if (!this.isOwnCheck) {
              this.backState();
            } else {
              this.ownCheckDone = false;
              this.isRestart = true;
            }

            this.page = 0;
            this.getPreCheckHistory();
            break;
          case 4: //処理再開
            break;
          case 5:
            this.saveState();
            break;
          default:
          //
        }
      }
    );
  }

  // 5分以上前か比較
  private isFiveMinutesPassed(timeString: string) {
    const targetDate = new Date(timeString);
    const now = new Date();
    const diff = now.getTime() - targetDate.getTime();
    const fiveMinutesInMilliseconds = 300000;

    return Math.abs(diff) > fiveMinutesInMilliseconds;
  }

  private backState() {
    this.innerState = this.processState;
    this.total = this.innerTotalCount;
    this.count = this.innerCount;
  }

  private saveState() {
    this.innerState = this.processState;
    this.innerTotalCount = this.total;
    this.innerCount = this.count;
  }

  private createDocumentId(officeId: number) {
    return String(officeId);
  }

  /** レセプト事前チェックの履歴を取得 */
  private getPreCheckHistory() {
    this.postJsonCheck(
      window.base_url + "/api/receipt/preCheckSameBuilding/history",
      {
        page: this.page,
        office_id: this.condition.search_cond.office_id,
      },
      (res) => {
        if (res.data.histories != null) {
          // 重複を除外している
          res.data.histories.forEach((history: PreCheckSameBuildingHistory) => {
            this.historyRecords[history.id] = history;
          });
          this.histories = Object.values(this.historyRecords);
          this.histories.sort((a, b) => b.id - a.id);

          if (res.data.histories.length < 10) {
            //これ以上読み込まない
            this.infiniteLoading.stateChanger.complete();
          }
          // まだ読み込む
          this.page += 1;
          this.infiniteLoading.stateChanger.loaded();
        } else {
          this.infiniteLoading.stateChanger.complete();
        }
      }
    );
  }

  /** 「処理開始」ボタン無効化 */
  private get StartButtonDisabled(): boolean {
    return [ProcessState.PROCESSING, ProcessState.DISABLED].includes(
      this.processState
    );
  }

  /** 「処理中断」ボタン無効化 */
  private get InterruptButtonDisabled(): boolean {
    return (
      [
        ProcessState.UNPROCESS,
        ProcessState.PROCESSED,
        ProcessState.INTERRUPTING,
        ProcessState.INTERRUPTED,
        ProcessState.DISABLED,
      ].includes(this.processState) || this.ownPreCheckProcessing() //処理中の時も一定条件で中断ボタン無効化
    );
  }

  // 処理中だが自分の処理でない
  private ownPreCheckProcessing(): boolean {
    return this.processState == ProcessState.PROCESSING && !this.isStaffIdOK;
  }

  /** 処理を中断する */
  private interruption() {
    // 中断のAPIを呼び出す

    this.postJsonCheck(
      window.base_url + "/api/receipt/aggregateSameBuildingAll/stop",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
      },
      (res) => {
        this.page = 0;
        this.getPreCheckHistory();
      }
    );
  }

  /** ダウンロードする */
  private downloadAll(item: any) {
    this.postJsonCheck(
      window.base_url + "/api/receipt/downloadPreCheckResultAll",
      {
        path: item.excel_path,
        id: item.id,
        office_id: this.condition.search_cond.office_id,
      },
      (res) => {
        location.href = res.data.path;
      },
      async (error, msg) => {
        let alertMsg = "レセプト事前チェック結果ダウンロードに失敗しました";
        if (!!error.isAxiosError && !error.response) {
          alertMsg =
            "インターネットに接続されていない可能性があります。インターネットに接続して再度お試しください。";
        }
        if (error.response && error.response.status == 429) {
          alertMsg =
            "サーバーが大変混み合っています。申し訳ありませんが、しばらくたってからやり直してください。";
        }

        // DBエラー
        if (error.response && error.response.data.code == 200) {
          alertMsg = "レセプト事前チェック結果ダウンロードに失敗しました";
        }
        // 業務エラー
        if (error.response && error.response.data.code == 101) {
          alertMsg = msg != "" ? msg : alertMsg;
        }

        await this.$openAlert(alertMsg);
        return;
      }
    );
  }

  /** 「処理中」に設定 */
  public setProcessing() {
    this.processState = ProcessState.PROCESSING;
  }

  /** 「処理完了」に設定 */
  public setProcessed() {
    this.processState = ProcessState.PROCESSED;
  }

  /** 「中断完了」に設定 */
  public setInterrupted() {
    this.processState = ProcessState.INTERRUPTED;
  }

  /** 処理開始 */
  private async startProcess() {
    if (
      !(await this.$openConfirm(
        "医療保険の同一建物居住者への訪問に該当するかをチェックして、自動で算定できます。よろしいですか？"
      ))
    ) {
      return;
    }

    // 請求履歴未確定の利用者のみ取得
    const patients: PatientInfo[] = this.selectPatients.filter(
      (sp: PatientInfo) => !sp.invoice_history && sp.medical
    );

    // 「処理中」に変更
    this.setProcessing();

    // 対象年月が指定されない場合は何もしない(リロードなど)
    if (this.condition.search_cond.yearmonth === null) {
      await this.$openAlert("事前チェック対象年月を前画面で選択してください。");
      return;
    }

    this.ownCheckDone = true;
    // 中断後自分のチェック以外が走ったら処理しない
    if (!this.isOwnCheck && this.isRestart) {
      await this.$openAlert("別の処理が開始されたため、処理再開できません。");
      this.processState = ProcessState.DISABLED;
      return;
    }
    this.postJsonBackgroundIgnore(
      window.base_url + "/api/receipt/aggregateSameBuildingAll",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
        patients: patients,
        restart: this.isRestart,
        id: this.preCheckId,
      },
      (res) => {
        //
      },
      async (error, msg) => {
        let alertMsg = "レセプト事前チェック実行に失敗しました";
        if (!!error.isAxiosError && !error.response) {
          alertMsg =
            "インターネットに接続されていない可能性があります。インターネットに接続して再度お試しください。";
        }
        if (error.response && error.response.status == 429) {
          alertMsg =
            "サーバーが大変混み合っています。申し訳ありませんが、しばらくたってからやり直してください。";
        }

        // DBエラー
        if (error.response && error.response.data.code == 200) {
          alertMsg = "レセプト事前チェック実行に失敗しました";
        }
        // 業務エラー
        if (error.response && error.response.data.code == 101) {
          alertMsg = msg != "" ? msg : alertMsg;
        }

        await this.$openAlert(alertMsg);
        this.processState = ProcessState.DISABLED;
        return;
      }
    );
  }

  /** Excelダウンロードパスが正しいかどうか*/
  private isInvalidLink(path: string) {
    const regex = /^\d+\/excel\/\d{4}-\d{2}\/.*\.xlsx$/;
    return !regex.test(path);
  }

  private closeWindow() {
    window.close();
  }

  private get BgColor() {
    return "rgb(251,245,229) !important"; // 常に指定した色にする
  }

  private get Color() {
    return "warning"; // 常にwarning色にする
  }

  private getColorByStatus(item: PreCheckSameBuildingHistory) {
    switch (item.status_flag) {
      case 0:
        return "success-outlined";
      case 1:
        if (this.isFiveMinutesPassed(item.date_processed)){
          return "error";
        }else{
          return "success-outlined";
        }
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "error";
    }
  }

  private async openDialog() {
    this.isOpenDialog = true;

    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!this.isOpenDialog) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }
}
