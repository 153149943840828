

































































































































































































































































































































































































































































import { Component, Prop, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import AppPlaces from "#/components/AppPlaces.vue";
import {
  MedicalInstitution,
  DefaultMedicalInstitution,
  Doctor,
  DefaultDoctor,
  DoctorContact,
  DefaultDoctorContact
} from "#/model/uniquemaster";
import { VForm } from "@/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import EditMixin from "@/mixins/editMixin";
import * as constant from "#/const";
import { DataTableHeader } from "vuetify";
import * as appCopy from "#/utility/appCopy";

interface DispMedicalInstitution extends Omit<MedicalInstitution, "doctors"> {
  doctors: DispDoctor[];
}
interface DispDoctor extends Doctor {
  index?: number;
}
interface DispDoctorContact extends DoctorContact {
  index?: number;
}
export const DefaultDispDoctor = (): DispDoctor => ({
  ...DefaultDoctor(),
  index: 0
});
export const DefaultDispDoctorContact = (): DispDoctorContact => ({
  ...DefaultDoctorContact(),
  index: 0
});
@Component({
  components: {
    AppPlaces,
    OfficeSelectInput
  }
})
export default class MedicalForm extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  EditMixin
) {
  @Ref("form") private readonly form!: VForm;
  @Ref("dialog") private readonly dialogForm!: VForm;

  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: null }) medicalId!: number;

  @Prop({
    type: Object,
    default: () => {
      return DefaultMedicalInstitution();
    }
  })
  content!: DispMedicalInstitution;

  // マスタメンテ以外からの操作の場合はfalse
  @Prop({ default: true }) isMaster!: boolean;

  public cloneContent?: DispMedicalInstitution = this.content;

  private cloneDoctor?: DispDoctor[] = [];

  private editingDoctor: DispDoctor = DefaultDispDoctor();

  private doctors: DispDoctor[] = [];

  private newDoctor?: DispDoctor = DefaultDispDoctor();

  private newContact?: DispDoctorContact = DefaultDispDoctorContact();

  private isOpenDoctorDialog = false;

  private isEdit = false;

  private editItemIndex = -1;

  private doctorLength = 0;

  private contactCnt = 0;

  private contentHeaders: DataTableHeader[] = [
    {
      text: "担当科",
      value: "section_name",
      width: "150px"
    },
    {
      text: "医師名",
      value: "full_name"
    },
    {
      text: "ふりがな",
      value: "furigana"
    },
    {
      text: "ステータス",
      value: "status"
    },
    {
      text: "留意事項",
      value: "remarks"
    },
    {
      text: "",
      value: "edit",
      width: "25px"
    }
  ];
  private subHeaders: DataTableHeader[] = [
    {
      text: "連絡先",
      value: "contact",
      filterable: false,
      sortable: false
    }
  ];
  //選択済みの適応事業所
  public selectedOffice: number[] = [];

  // 削除ボタン表示フラグ
  private get showDelete(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_MEDICAL_INSTITUTION,
      constant.AGREEMENT.FUNCTION_DIV_DELETE
    );
  }

  // 医師追加ボタン表示フラグ
  private get showAddDoctor(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_DOCTOR,
      constant.AGREEMENT.FUNCTION_DIV_INSERT
    );
  }

  // 医師削除ボタン表示フラグ
  private get showDeleteDoctor(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_DOCTOR,
      constant.AGREEMENT.FUNCTION_DIV_DELETE
    );
  }

  created() {
    this.cloneContent = this.content;
    this.cloneDoctor = this.content.doctors;
    if (this.medicalId > 0) {
      this.getMedicalInstitution();
    } else {
      this.setLoaded();
    }
  }

  public async saveMedicalInstitution(): Promise<boolean> {
    // cloneContentがundefinedでないことを確認
    if (this.cloneContent) {
      // short_nameが空であるかどうかを確認
      if (!this.cloneContent.short_name) {
        // nameがundefinedでないことを確認
        if (this.cloneContent.name) {
          // nameの最初の20文字を取得
          this.cloneContent.short_name = this.cloneContent.name.substring(
            0,
            20
          );
        }
      }
    }
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return false;
    }
    if (
      this.cloneContent?.office_code.slice(-7) !==
      this.cloneContent?.medical_institution_code
    ) {
      if (
        !(await this.$openConfirm(
          "事業所番号の末尾7文字が、医療機関コードと異なります。よろしいですか？"
        ))
      ) {
        return false;
      }
    }
    if (
      this.isMaster &&
      (!this.cloneContent?.doctors || this.cloneContent.doctors.length < 1)
    ) {
      if (
        !(await this.$openConfirm("医師が未登録ですがこのまま保存しますか？"))
      ) {
        return false;
      }
    } else {
      if (!(await this.$openConfirm("保存しますか？"))) {
        return false;
      }
    }
    if (this.cloneContent) {
      this.cloneContent.medical_institution_offices = this.selectedOffice.map(
        off => {
          return {
            id: 0,
            medical_institution_id: 0,
            office_id: off
          };
        }
      );
      this.postJsonCheck(
        window.base_url + "/api/master/medicalinstitution/save",
        {
          medical_institution: this.cloneContent
        },
        () => {
          this.setNoEditMode();
          if (this.isMaster) {
            this.$router.push({ name: "MedicalEntry" });
          }
        }
      );
    }
    return true;
  }

  private getMedicalInstitution() {
    this.postJsonCheck(
      window.base_url + "/api/master/medicalinstitution/get",
      {
        medical_institution_id: Number(this.medicalId)
      },
      res => {
        this.cloneContent = { ...res.data.medical_institution };
        let headDoctor = "";
        if (this.cloneContent && this.cloneContent.doctors) {
          this.doctors = this.cloneContent.doctors;
          let loopCnt = 0;
          this.cloneContent.doctors.forEach(doctor => {
            doctor.index = loopCnt; // 既に追加されている医師の数(expand対応)
            loopCnt++;
          });
          this.doctorLength = this.cloneContent.doctors.length;
          headDoctor = "　" + this.doctors[0].full_name; //異なる宛名連結用先頭の医師名
        }
        this.cloneContent?.medical_institution_offices?.forEach(office => {
          if (office.office_id) {
            this.selectedOffice.push(office.office_id);
          }
        });
        if (this.cloneContent) {
          // 送付状の住所が登録先と異なる宛名、ふりがなが空の場合、機関名、ふりがなから転記
          if (!this.cloneContent?.letter_name) {
            this.cloneContent.letter_name = this.cloneContent.name + headDoctor;
          }
          if (!this.cloneContent?.letter_furigana) {
            this.cloneContent.letter_furigana = this.cloneContent.furigana;
          }
        }
        this.setLoaded();
      }
    );
  }

  private async deleteDoctor() {
    if (!(await this.$openConfirm("医師を削除します。よろしいですか？"))) {
      return;
    }
    this.cloneContent?.doctors?.splice(this.editItemIndex, 1);
    this.isOpenDoctorDialog = false;
  }

  private addNewContact(doctor: DispDoctor) {
    if (doctor && this.newContact) {
      if (doctor.id) {
        this.newContact.doctor_id = Number(doctor.id);
      }
      this.contactCnt++; // 重複しないようインクリメント
      this.newContact.index = this.contactCnt;

      if (!doctor.doctor_contacts) {
        doctor.doctor_contacts = [];
      }
      doctor.doctor_contacts.push({ ...this.newContact });
    }
    this.newContact = DefaultDispDoctorContact();
  }

  private async deleteMedicalInst() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/master/medicalinstitution/delete",
        {
          medical_institution: this.cloneContent
        },
        () => {
          this.setNoEditMode();
          this.$router.push({ name: "MedicalEntry" });
        }
      );
    }
  }
  private addNewDoctor() {
    if (this.newDoctor && this.cloneContent) {
      this.newDoctor.is_empty = 1;
      this.doctorLength++;
      this.newDoctor.index = this.doctorLength; // 既に追加されている医師の数
      this.editingDoctor = appCopy.deepCopy(this.newDoctor);
      this.addNewContact(this.editingDoctor); // 連絡先項目を追加
      this.isEdit = false;
      this.isOpenDoctorDialog = true;
      // if (!this.dialogForm.validate) this.dialogForm.resetValidation();
    }
    this.newDoctor = DefaultDispDoctor();
  }
  // 医師登録
  private saveDoctor() {
    if (!this.dialogForm.validate()) {
      return;
    }
    if (!this.isEdit && this.newDoctor && this.cloneContent) {
      /* 追加 */
      this.newDoctor.is_empty = 1;

      // 連絡先が入力されているもののみ保存
      const contactList = this.editingDoctor.doctor_contacts.filter(contact => {
        return contact.contact_type !== 0 || contact.contact_info !== "";
      });
      this.editingDoctor.doctor_contacts = contactList;

      this.cloneContent.doctors?.push(this.editingDoctor);
      this.isOpenDoctorDialog = false;
    } else if (this.isEdit && this.cloneContent) {
      /* 編集 */
      // インデックス見つからなかった場合は追加する
      if (this.editItemIndex < 0) {
        if (this.newDoctor) this.newDoctor.is_empty = 1;
        this.cloneContent.doctors?.push(this.editingDoctor);
        this.isOpenDoctorDialog = false;
      }
      // 連絡先が入力されているもののみ保存
      const contactList = this.editingDoctor.doctor_contacts.filter(contact => {
        return contact.contact_type !== 0 || contact.contact_info !== "";
      });
      this.editingDoctor.doctor_contacts = contactList;
      Object.assign(
        this.cloneContent.doctors[this.editItemIndex],
        this.editingDoctor
      );
      this.isOpenDoctorDialog = false;
    }
  }

  // 医師編集
  private isEditDoctor(rowItem: DispDoctor) {
    // インデックス取得
    this.editItemIndex = this.cloneContent?.doctors.indexOf(rowItem) ?? -1;
    this.editingDoctor = appCopy.deepCopy(rowItem);

    this.contactCnt = this.editingDoctor.doctor_contacts.length;
    this.addNewContact(this.editingDoctor); // 連絡先項目を追加

    this.isEdit = true;
    this.isOpenDoctorDialog = true;
  }

  /** 医療機関コードバリデーション */
  private ruleMedicalInstCode(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    return this.strLength(
      this.cloneContent.medical_institution_code,
      7,
      7,
      "7文字で入力してください"
    );
  }

  /** 事業所番号バリデーション */
  private ruleOfficeCode(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.office_code.length !== 10) {
      return "半角英数字10文字でご記入ください";
    }
    if (this.cloneContent.pref !== 0) {
      const prefName = this.prefName(this.cloneContent.pref);
      const prefCode = ("00" + this.cloneContent.pref).slice(-2);
      if (prefCode !== this.cloneContent.office_code.slice(0, 2)) {
        return `住所に${prefName}を選択している場合、先頭2文字は${prefCode}にしてください`;
      }
    }
    return true;
  }

  private requiredStr(str: string): boolean | string {
    return this.required(str, "必須です");
  }

  private requiredName(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.name.length > 0) {
      return true;
    }
    return this.required(this.cloneContent.name, "必須です");
  }

  private requiredFurigana(): boolean | string {
    if (!this.cloneContent) {
      return true;
    }
    if (this.cloneContent.furigana.length > 0) {
      return true;
    }
    return this.required(this.cloneContent.furigana, "必須です");
  }

  private requiredStatus(doctor: DispDoctor): boolean | string {
    if (!doctor.status) {
      return true;
    }
    if (doctor.status > 0) {
      return true;
    }
    return this.required(
      String(doctor.status),
      "医師を追加される場合はステータスも必須です。"
    );
  }
  private requiredDocFullName(namePart: string): boolean | string {
    if (namePart.length > 0) {
      return true;
    }
    return this.required(namePart, "※医師を追加される場合は医師名も必須です。");
  }
  private requiredDocFurigana(namePart: string): boolean | string {
    if (namePart.length > 0) {
      return true;
    }
    return this.required(
      namePart,
      "※医師を追加される場合はふりがなも必須です。"
    );
  }

  //連絡先削除
  private async deleteConfirm(doctor: DispDoctor, index: number) {
    if (!(await this.$openConfirm("連絡先を削除しますか？"))) {
      return;
    }
    doctor.doctor_contacts?.splice(index, 1);
  }

  //編集モード設定
  @Watch("cloneContent", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  private getStatus(index: number) {
    if (!index) return;
    return this.masters.staff_statuses.filter(item => {
      return item.value === index;
    })[0].text;
  }

  private getContactType(type: number) {
    if (!type) return;
    return this.contactTypes.filter(item => {
      return type === item.value;
    })[0].text;
  }

  // ダイアログのタイトル取得
  private get dialogTitle(): string {
    if (this.isEdit) {
      return "医師を編集する";
    } else {
      return "医師を追加する";
    }
  }

  private closeDialog() {
    this.isOpenDoctorDialog = false;
  }

  // 入力値クリア
  public clear() {
    this.cloneContent = DefaultMedicalInstitution();
    this.form.resetValidation();
  }
}
