var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isOffsetTab)?_c('AppTabs',{staticClass:"mb-4",attrs:{"centerActive":false,"showArrows":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('AppTab',[_vm._v("未提出")]),_c('AppTab',[_vm._v("提出済み")])],1):_vm._e(),_c('div',{staticClass:"base-report-list"},[(_vm.isShowSubHeader)?_c('v-row',{staticClass:"mb-4",staticStyle:{"gap":"5px 0px"},attrs:{"no-gutters":"","align":"center","justify":"start"}},[_c('AppSecondTitle',{attrs:{"label":_vm.title,"hint":_vm.hint}}),_vm._t("title"),_c('v-spacer'),_c('v-row',{staticStyle:{"gap":"5px 0px"},attrs:{"justify":"end","no-gutters":""}},[_vm._t("default"),(!_vm.IsFocusingMode)?_c('AppButton',{staticClass:"ml-4",attrs:{"id":"makenew","height":"36px","color":"primary","outlined":""},on:{"click":_vm.clickNew}},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"add-1","fill":_vm.$vuetify.theme.themes.light.primary}}),_vm._v("新しく作成する")],1):_vm._e()],2)],2):_vm._e(),_c('div',[_c('AppDataTable',{attrs:{"headers":_vm.EditHeaders,"items":_vm.tabItems,"search":_vm.Keyword,"label":"利用者名・ID・被保険者番号（介護保険）","isNoFooter":true,"no-data-text":"作成した書類はありません。"},on:{"update:search":[function($event){_vm.Keyword=$event},_vm.updateKeyword],"open-filter":function($event){return _vm.openFilter()},"click:row":function($event){return _vm.clickRow($event)}},scopedSlots:_vm._u([(_vm.$scopedSlots.top)?{key:"top",fn:function(){return [_vm._t("top")]},proxy:true}:(!_vm.isOffsetTab)?{key:"top",fn:function(){return [_c('AppTabs',{staticClass:"base-report-list__tabs",attrs:{"centerActive":false,"showArrows":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('AppTab',[_vm._v("未提出")]),_c('AppTab',[_vm._v("提出済み")])],1)]},proxy:true}:null,{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == _vm.REPORT_SAVE)?_c('AppChip',{staticClass:"text-no-wrap",attrs:{"color":"success"}},[_vm._v("保存")]):_vm._e(),(item.status == _vm.REPORT_SUBMIT)?_c('AppChip',{staticClass:"text-no-wrap",attrs:{"outlined":"","color":"primary"}},[_vm._v("提出済")]):_vm._e(),(item.status == _vm.REPORT_REMAND)?_c('AppChip',{staticClass:"text-no-wrap",attrs:{"outlined":"","color":"error"}},[_vm._v("差戻し")]):_vm._e()]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._t("comment",null,{"item":item})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_vm._t("edit",function(){return [_c('v-row',{attrs:{"no-gutters":""}},[(
                  item.status == _vm.REPORT_SAVE || item.status == _vm.REPORT_REMAND
                )?_c('AppButton',{staticClass:"primary mr-2",attrs:{"height":"36px"},on:{"click":function($event){$event.stopPropagation();return _vm.clickEdit(item)}}},[_vm._v(" 編集 ")]):_vm._e(),(item.status == _vm.REPORT_SUBMIT)?_c('AppButton',{staticClass:"primary mr-2",attrs:{"height":"36px"},on:{"click":function($event){$event.stopPropagation();return _vm.clickPdf(item)}}},[_vm._v(" PDF ")]):_vm._e(),(
                  item.status == _vm.REPORT_SUBMIT &&
                    _vm.loginUserAuthId > _vm.STAFF_COMMON
                )?_c('AppButton',{staticClass:"primary",attrs:{"height":"36px"},on:{"click":function($event){$event.stopPropagation();return _vm.clickRemandBtn(item)}}},[_vm._v(" 差戻し ")]):_vm._e()],1)]},{"item":item})]}},{key:"item.updated_at",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at_wareki)+" ")]}},_vm._l((_vm.$scopedSlots),function(slot,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)}),(_vm.tab == 1)?_c('InfiniteLoading',{ref:"infiniteLoading",attrs:{"spinner":"spiral"},on:{"infinite":_vm.infiniteHandler}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e()],1)],1),_c('AppDialog',{attrs:{"title":"差戻し","saveLabel":"差戻す"},on:{"save":_vm.clickRemand,"cancel":function($event){_vm.isRemandDialogOpen = false}},model:{value:(_vm.isRemandDialogOpen),callback:function ($$v) {_vm.isRemandDialogOpen=$$v},expression:"isRemandDialogOpen"}},[_c('AppTextField',{attrs:{"label":"コメント","maxlength":"100","counterNum":100},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }