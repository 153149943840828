





















































































































import { Component, Ref } from "vue-property-decorator";
import ReceiptEditAddCareBase from "./ReceiptEditAddCareBase";
import VisitMessage from "./common/VisitMessage.vue";
import VisitTitle from "./common/VisitTitle.vue";
import VisitInfo from "./care/VisitInfo.vue";
import VisitContentDiv from "./care/VisitContentDiv.vue";
import StaffDiv from "./care/StaffDiv.vue";
import TimeDiv from "./care/TimeDiv.vue";
import OneMonthAdd from "./care/OneMonthAdd.vue";
import EveryTime from "./care/EveryTime.vue";
import FirstFlg from "./care/FirstFlg.vue";
import Other from "./care/Other.vue";
import ReceiptEditAddBase from "./ReceiptEditAddBase";

@Component({
  components: {
    VisitMessage,
    VisitTitle,
    VisitInfo,
    VisitContentDiv,
    StaffDiv,
    TimeDiv,
    OneMonthAdd,
    EveryTime,
    FirstFlg,
    Other
  }
})
export default class ReceiptEditAddCareV1 extends ReceiptEditAddCareBase
  implements ReceiptEditAddBase {
  @Ref("oneMonthAdd") private readonly oneMonthAdd!: OneMonthAdd;
  @Ref("everyTime") private readonly everyTime!: EveryTime;
  @Ref("firstFlg") private readonly firstFlg!: FirstFlg;
  @Ref("other") private readonly other!: Other;

  //-----------------------------------------------------
  // ボタン群
  //-----------------------------------------------------
  //登録
  async registration(onSuccess?: Function) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    //請求履歴確定済
    if (this.isBilledCare == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //サービス訪問（自費）チェック時
    if (this.service_visit) {
      if (
        !(await this.$openConfirm(
          "請求対象外となるため画面から消えますが宜しいですか？"
        ))
      ) {
        return;
      }
    }

    //対象月で職員区分「専門」での訪問が2回目以降の場合
    if (this.receiptVisitRecord.staff_div == 3) {
      //登録対象より前の訪問で職員区分「専門」の訪問がある場合
      if (this.event.specialty_hide_flg) {
        if (
          !(await this.$openConfirm(
            "請求対象外となるため画面から消えますが宜しいですか？"
          ))
        ) {
          return;
        }
      }
    }

    //登録対象が在宅がんで同じ期間に介護訪問がある場合
    if (this.homeCancerCareFlg) {
      if (
        !(await this.$openConfirm(
          "在宅がん医療総合診療料の期間に介護保険の実績が含まれています" +
            this.homeCancerCareDay +
            "日。本当に登録しますか？（ケアマネジャーへご確認ください。）"
        ))
      ) {
        return;
      }
      //登録対象が在宅がんの場合
    } else if (this.event.home_cancer_flg == 1) {
      if (
        !(await this.$openConfirm(
          "在宅がん医療総合診療料期間の実績は請求対象外となるため保険請求には反映されませんが宜しいですか？"
        ))
      ) {
        return;
      }
    }

    //clearDisableItem（disableの項目は0で保存しに行く）
    this.oneMonthAdd.clearDisableItem();
    this.everyTime.clearDisableItem();
    this.firstFlg.clearDisableItem();
    this.other.clearDisableItem();

    //バックエンド側で登録時チェックする
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/registration",
      {
        patient_id: this.patientId,
        service_visit: this.service_visit,
        yearmonth: this.yearmonth,
        event: this.event,
        receipt_visit_record: this.receiptVisitRecord,
        visit_content_div: this.visit_content_div
      },
      async res => {
        this.registrationMessage = res.data.registration_message;
        this.message("登録しました");
        this.update();
        onSuccess?.();
      },
      err => {
        if (err.response?.data.registration_message) {
          this.registrationMessage = err.response?.data.registration_message;
        }
      }
    );
  }

  //実績解除
  async releaseResult(onSuccess?: Function) {
    //請求履歴確定済
    if (this.isBilledCare == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //確認
    if (!(await this.$openConfirm("実績解除します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/releaseResult",
      {
        patient_id: this.patientId,
        receipt_visit_record: this.receiptVisitRecord,
        yearmonth: this.yearmonth,
        office_id: this.officeHistory.office_id
      },
      () => {
        this.message("登録実績を解除しました");
        this.update();
        onSuccess?.();
      }
    );
  }

  //リセット
  async reset(onSuccess?: Function) {
    //請求履歴確定済
    if (this.isBilledCare == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //確認
    if (
      !(await this.$openConfirm(
        "記録書IIを再提出します。加算情報等、初期化されますが宜しいですか？"
      ))
    ) {
      return;
    }

    //医療保険に変更（介護訪問なのに介護保険がないもしくは、指示書が医療扱い）
    if (this.judgeMed()) {
      //リセットメッセージ
      const resetColumns = this.resetColumns();

      if (resetColumns != "") {
        if (
          !(await this.$openConfirm(
            "適用保険が医療保険に変更されているため、" +
              resetColumns +
              "の選択が解除されますがよろしいですか？"
          ))
        ) {
          return;
        }
      }
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/reset",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth,
        event: this.event
      },
      async res => {
        this.resetMessage = res.data.reset_message;
        this.message("リセットしました");
        this.update();
        onSuccess?.();
      },
      err => {
        if (err.response?.data.reset_message) {
          this.resetMessage = err.response?.data.reset_message;
        }
      }
    );
  }
}
