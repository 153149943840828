







































































































































































































































































































































































































import { Component, Ref } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import DuplicateCheckDialog from "@/components/patient/info/DuplicateCheckDialog.vue";
import Base from "@/components/patient/info/Base";
import { VForm } from "@/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import FamilyRelationShipSelect from "@/components/common_ibow/FamilyRelationShipSelect.vue";
import DateInput from "#/components/DateInput.vue";
import * as constant from "#/const";
import RequesterType from "@/components/patient/accept/RequesterType.vue";

@Component({
  components: {
    AppPlaces,
    DuplicateCheckDialog,
    OfficeSelect,
    DateInput,
    FamilyRelationShipSelect,
    RequesterType
  }
})
export default class PatientAccept extends Base {
  @Ref("form") private readonly form!: VForm;
  @Ref("riform") private readonly riForm!: VForm;

  @Ref("duplicateCheckDialog")
  private readonly duplicateCheckDialog!: DuplicateCheckDialog;

  @Ref("search") private readonly searchForm!: VForm;
  @Ref("rtdialog") private readonly rtDialog!: RequesterType;
  private breadItems = [
    {
      text: "利用者",
      disabled: false,
      to: "/patient/list"
    },
    {
      text: "利用者新規受付",
      disabled: true,
      to: ""
    }
  ];

  /** 訪問場所定数 */
  private VISIT_PLACE_DIV_FACILITY = constant.PATIENT.VISIT_PLACE_DIV_FACILITY;

  public created() {
    this.useType = 0;

    this.postJsonCheck(
      window.base_url + "/api/patients/accept/get",
      {},
      res => {
        if (res.data) {
          this.relations = res.data.relations;
          this.medicals_doctor_only = res.data.medicals;
          this.patient.info.office_id = this.loginUser.office_id;
          this.relationInstitutions = res.data.relation_institutions ?? [];
          this.medInstitutions = res.data.med_institutions ?? [];
          this.setLoaded();
        } else {
          this.setLoaded();
        }
      }
    );
  }

  // 登録ボタン押下
  private async clickRegist() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    this.resetFacilityCode();

    this.postJsonCheck(
      window.base_url + "/api/patients/accept/regist",
      { patient: this.patient },
      async res => {
        if (res.data) {
          if (res.data.err_messages && res.data.err_messages.length > 0) {
            // バリデーションエラーあり
            let message = "";
            (res.data.err_messages as string[]).forEach(err => {
              message += err;
            });
            await this.$openAlert(message);
            return;
          }
        }
        this.setNoEditMode();
        this.$router.go(-1);
      }
    );
  }

  // キャンセルボタン押下
  private clickCancel() {
    this.$router.go(-1);
  }

  // 重複登録チェックボタン押下
  private clickDuplicateName() {
    this.duplicateCheckDialog.open();
  }

  // ダイアログを開く
  private openDialog() {
    this.rtDialog.openDialog();
  }

  private select(id: number) {
    if (this.patient.info.requester_type === this.PRIREQUESTER_TYPE.RELATED) {
      this.patient.info.requester_personnel_id = id;
    } else if (
      this.patient.info.requester_type === this.PRIREQUESTER_TYPE.MEDICAL
    ) {
      this.patient.info.requester_doctor_id = id;
    }
  }
}
