




























































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import AppDataTable from "./AppDataTable.vue";
import AppButton from "./AppButton.vue";
import AppSecondTitle from "./AppSecondTitle.vue";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppDataTable,
    AppButton,
    AppSecondTitle,
    AppIcon
  }
})
export default class ItemTable extends Vue {
  @Prop({ default: "" })
  title!: string;
  @Prop({ default: () => [] })
  headers!: [];
  @Prop({ default: () => [] })
  items!: [];
  /** 職員権限区分\n0:一般職員\n1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない） */
  @Prop({ default: 1 })
  authDiv!: number;
  /** 新規作成できるか */
  @Prop({ default: true })
  isAllowCreate!: number;
  @Prop({ default: "" })
  item_class!: string;

  @Emit()
  makenew() {
    return;
  }

  @Emit()
  repair(item: unknown) {
    return item;
  }

  @Emit()
  check() {
    return;
  }

  @Emit()
  action() {
    return;
  }

  @Emit()
  copy(item: unknown) {
    return item;
  }

  private get editHeaders(): unknown[] {
    if (this.authDiv == 1) {
      return this.headers;
    }
    return this.headers.slice(1);
  }
}
