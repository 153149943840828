






































































































































































































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import { cantMoving } from "@/main";
import { Menu } from "@/types";
import { SideMenu } from "@/components/app/types";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import ChikenLauncher from "../home/chikenLauncher.vue";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";

@Component({
  components: { ChikenLauncher }
})
export default class AppMenu extends Mixins(
  FireStoreMixin,
  UtilMixin,
  AxiosMixin
) {
  @Prop() sideMenu!: SideMenu;

  @Ref("chikenLauncher") private readonly chikenLauncher!: ChikenLauncher;

  private get AppVersion() {
    return window.app_version;
  }

  private get officeName() {
    return this.masters.offices.find(
      office => office.value === this.loginUser.office_id
    )?.text;
  }

  // URLのパス部のスラッシュ有無を統一
  private get url() {
    return (url: string) => {
      // 先頭スラッシュは付ける
      url = url[0] === "/" ? url : "/" + url;
      // ホーム画面でない限り末尾スラッシュは付けない
      url = url.slice(-1) === "/" && url !== "/" ? url.slice(0, -1) : url;
      return url;
    };
  }

  // 遷移しようとするURLは現在のと同じまたは子孫階層か
  private get isUrlSameChild() {
    return (urlTo: string) => {
      const urlSlashCount = this.url(urlTo).match(/\//g)?.length ?? 0;
      // ルートの子階層URLが遷移先であれば、子孫にもマッチする
      if (urlSlashCount === 1 && urlTo.length !== 1) {
        return this.$route.path.startsWith(this.url(urlTo));
      }
      return this.url(urlTo) === this.$route.path;
    };
  }

  private async linkto(menu: Menu) {
    //iBowレセプトの場合
    if (menu.id == 17) {
      const url = menu.url + "?fromIbow=1";
      window.open(url, "_blank");
    } else if (menu.id == 171) {
      //旧iBowレセプトの場合
      //ワンタイムトークン
      let oneTimeToken = "";
      this.postJsonCheck(
        window.base_url + "/api/receipt/v1onetimetoken",
        {},
        res => {
          oneTimeToken = res.data.one_time_token;
          const url = menu.url + "/?onetime=" + oneTimeToken;
          window.open(url, "_blank");
        }
      );
    } else if (menu.id == 22) {
      //iBow治験の場合
      this.chikenLauncher.launch(menu.url);
    } else {
      // 同じページには遷移しない
      if (this.url(menu.url) === this.$route.path) return;

      if (menu.is_outer == 1) {
        window.open(menu.url, "_blank");
      } else if (menu.is_outer == 2) {
        location.href = menu.url;
      } else if (menu.is_outer == 101) {
        //けあログっとを開く
        this.postJsonCheck(
          window.auth_backend_url + "/outer/auth/portal/token",
          { portal_office_staff_id: this.loginUser.portal_staff_id },
          res => {
            this.setCookie(res);
            location.href = menu.url;
          }
        );
      } else {
        this.$router.push(menu.url);
      }
    }
  }

  //ログアウト
  private async logout() {
    if (!(await this.$openConfirm("ログアウトしますか？"))) {
      return;
    }
    this.toLogout();
  }

  //ログアウト
  @Emit()
  private toLogout() {
    return;
  }

  //マイページへ
  private async toMypage() {
    // 編集を破棄したくない場合は処理しない
    if (await cantMoving()) {
      return;
    }
    location.href = window.auth_frontend_url;
  }

  //サイドメニューの縮小を解除
  private sideExpand() {
    if (this.sideMenu.isNarrow) {
      this.toggleSideNarrow();
    }
  }

  @Emit()
  private toggleSideOpen() {
    return;
  }

  //サイドメニューを狭くする(PC幅)
  @Emit()
  private toggleSideNarrow() {
    return;
  }

  /** クッキーに設定 */
  private setCookie(res: AxiosResponse) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, {
      domain: window.cookie_domain
    });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
  }
}
