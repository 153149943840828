import Base from "../base";

export interface SupportableDateSetting extends Base {
  /** サポート対応可否設定ID */
  id: number;
  /** 対応日 */
  date: string;
  /** 対応可否\n0:対応可能\n1:対応不可能 */
  supportable: number;
}

export const DEFAULT_SUPPORTABLE_DATE_SETTING: SupportableDateSetting = {
  id: 0,
  date: "",
  supportable: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
};
