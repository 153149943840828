/** 「iBow AI自動作成」作成履歴 */
export interface AiGenerateHistory {
  /** ID */
  id: string;
  /** iBowアカウントID */
  ibow_user_id: number;
  /** 契約ID */
  agreement_id: number;
  /** 作成職員ID */
  staff_id: number;
  /** 作成職員事業所ID */
  staff_office_id: number;
  /** 作成職員名 */
  staff_name: string;
  /** 利用者ID */
  patient_id: number;
  /** 利用者事業所ID */
  patient_office_id: number;
  /** 利用者名 */
  patient_name: string;
  /** 利用者ふりがな */
  patient_name_kana: string;
  /** 作成日時 */
  datetime: string;
  /** 作成日時(和暦) */
  datetime_wareki: string;
  /** 作成状況 */
  state: number;
  /** 書類種別 1:看護計画書 2:月次報告書 */
  report_type: number;
  /** 書類ID */
  report_id: number;
  /** 対象年月(yyyyMM) */
  yearmonth: number;
  /** 類似検索でヒットしたコンテキスト情報 */
  context: string;
  /** 最終プロンプト */
  prompt: string;
  /** 回答原文 */
  answer: string;
}

export const DefaultAiGenerateHistory = (): AiGenerateHistory => ({
  id: "",
  ibow_user_id: 0,
  agreement_id: 0,
  staff_id: 0,
  staff_office_id: 0,
  staff_name: "",
  patient_id: 0,
  patient_office_id: 0,
  patient_name: "",
  patient_name_kana: "",
  datetime: "",
  datetime_wareki: "",
  state: 0,
  report_type: 0,
  report_id: 0,
  yearmonth: 0,
  context: "",
  prompt: "",
  answer: ""
});

/** 「iBow AI自動作成」月次報告書作成オプション */
export interface IbowAiGenerateMonthReportOption {
  /** 1:別添を自動作成 */
  generate_betten: number;
  /** 1:「日常生活自立度」を自動作成 */
  generate_daily_life_rate_div: number;
  /** 1:「認知症高齢者の日常生活自立度」を自動作成*/
  generate_dementia_daily_life_rate_div: number;
  /** 1:「理学療法士、作業療法士又は言語聴覚士が行った訪問看護、家族等への指導、リスク管理等の内容」を自動作成*/
  generate_contents: number;
  /** 1:「ADL評価」を自動作成*/
  generate_adl: number;
  /** 1:「コミュニケーション」を自動作成*/
  generate_communication: number;
  /** 1:「家庭内の役割」を自動作成*/
  generate_role_in_the_home: number;
  /** 1:「余暇活動」を自動作成*/
  generate_leisure_activities: number;
  /** 1:「社会地域活動」を自動作成*/
  generate_volunteer_activities: number;
  /** 1:「終了後に行いたい社会参加等の取組」を自動作成*/
  generate_activities_after: number;
  /** 1:プロンプトに「本日の状態（バイタル）」を含める */
  use_vital: number;
  /** 1:プロンプトに「観察項目」を含める */
  use_observation: number;
  /** 1:プロンプトに「症状報告」を含める */
  use_condition: number;
  /** 1:別添作成時に計画書を参照する */
  use_vn_plan: number;
  /** 1:前回提出分の月次報告書を利用して文章スタイルを模倣させる */
  use_pre_month_report: number;
}

export const DefaultIbowAiGenerateMonthReportOption = (): IbowAiGenerateMonthReportOption => ({
  generate_betten: 0,
  generate_daily_life_rate_div: 0,
  generate_dementia_daily_life_rate_div: 0,
  generate_contents: 0,
  generate_adl: 0,
  generate_communication: 0,
  generate_role_in_the_home: 0,
  generate_leisure_activities: 0,
  generate_volunteer_activities: 0,
  generate_activities_after: 0,
  use_vital: 0,
  use_observation: 0,
  use_condition: 1,
  use_vn_plan: 0,
  use_pre_month_report: 0
});

/** 「iBow AI自動作成」作成オプション */
export interface IbowAiGenerateOption {
  month_report: IbowAiGenerateMonthReportOption;
}

export const DefaultIbowAiGenerateOption = (): IbowAiGenerateOption => ({
  month_report: DefaultIbowAiGenerateMonthReportOption()
});
