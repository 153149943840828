import { PatientState } from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import {
  PatientDispInfoDetailValues,
  DetailDefaultValues
} from "@/components/patient/types";

export const state: PatientState = {
  info: PatientDispInfoDetailValues(),
  detail: DetailDefaultValues(),
  report_count: {
    bedsore_plan_not_count: 0,
    care_summary_not_count: 0,
    conference_report_not_count: 0,
    month_report_not_count: 0,
    occur_report_not_count: 0,
    provision_report_not_count: 0,
    service_report_not_count: 0,
    vn_plan_not_count: 0,
    vn_record_not_count: 0,
    visit_record_not_count: 0,
    fulltime_not_read_count: 0
  },
  master: {
    relation_institutions: [],
    medical_institutions: [],
    patient_menus: [],
    ibow_ai_generate_agreement_status: {
      vn_plan_agreement_status: 0,
      month_report_agreement_status: 0
    },
    is_ai_month_report_betten_enabled: 0
  },
  bread_items: [],
  office_id: 0,
  error: false
};

const namespaced = true;

export const patient: Module<PatientState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
